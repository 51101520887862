import { useDispatch, useSelector } from 'react-redux';
import { setModalSection } from '../../slices/tradeSlice';
import { useNavigate } from 'react-router-dom';

const CurrencySelect = ({ width = 'w-full' }) => {
  const { modalSection, selectedCoin, availableCoins } = useSelector(
    (state) => state.trade
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const CurrencyList = () => {
    return (
      <div className="absolute top-[105%] max-h-[30vh] overflow-auto left-0 gap-[10px] z-20 w-full flex flex-col bg-[rgba(255,255,255,0.10)] backdrop-blur-md rounded-lg border border-[#DADADA] p-[12px]">
        {availableCoins.map((coin) => (
          <span
            key={coin._id}
            className="flex flex-row items-center text-sm sm:text-base"
            onClick={() => navigate(`/trade/${coin._id}`, { replace: true })}
          >
            <figure className="sm:flex hidden w-[36px] h-[36px] rounded-full bg-green mr-[8px]">
              <img
                src={coin.icon}
                alt="coin-logo"
                className="w-full h-full rounded-full object-contain"
              />
            </figure>
            <p
              className={`text-ellipsis overflow-hidden ${
                selectedCoin._id === coin._id
                  ? 'font-poppins-bold'
                  : 'font-poppins'
              }`}
            >
              {coin.currency}/USD
            </p>
          </span>
        ))}
      </div>
    );
  };

  return (
    <>
      {selectedCoin ? (
        <div
          className={`relative flex cursor-pointer flex-col sm:flex-row sm:py-0 bg-white-btn sm:bg-transparent py-[12px] px-[6px] rounded-lg ${width}`}
          onClick={() => dispatch(setModalSection('CURRENCY_LIST'))}
        >
          <p className="text-[10px] block sm:hidden text-[#70808C]">Currency</p>
          <div className="sm:flex hidden w-[46px] h-[46px] rounded-full bg-green mr-[8px]">
            <img
              src={selectedCoin.icon}
              alt="coin-logo"
              className="w-full h-full rounded-full object-contain"
            />
          </div>
          <span className="flex flex-row justify-between items-center sm:gap-[4px]">
            <p className="sm:text-lg text-sm">{selectedCoin.currency}/USD</p>
            <ion-icon
              name={
                modalSection === 'CURRENCY_LIST'
                  ? 'chevron-up-outline'
                  : 'chevron-down-outline'
              }
              className="w-[8px] h-[8px] sm:w-[16px] sm:h-[16px]"
            ></ion-icon>
          </span>
          {modalSection === 'CURRENCY_LIST' ? <CurrencyList /> : null}
        </div>
      ) : null}
    </>
  );
};

export default CurrencySelect;
