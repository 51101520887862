import { createSlice } from '@reduxjs/toolkit';
import {
  fetchBinaryOptionDetail,
  fetchBinaryOptionList,
  tradeOption,
} from '../actions/trade';
import { calculateRatio } from '../utils/math';

const initialState = {
  modalSection: '',
  isLoading: false,
  timeFilter: ['1 min', '5 min', '30 min', '1 hr', '5 hr', '1 d', '1 w'],
  chartTypes: ['Candlestick', 'Line'],
  selectedChart: 'Line',
  selectedTimeFilter: '5 min',
  availableOptions: null,
  availableCoins: null,
  selectedCoin: null,
  tradeGroups: null,
  selectedGroup: null,
  selectedBanner: null,
  betAmount: 0,
  marketInfo: null,
  joinedGroup: false,
  marketRatio: {
    bull: 0,
    bear: 0,
  },
  myTrade: {
    bear: 0,
    bull: 0,
  },
};

export const trade = createSlice({
  name: 'trade',
  initialState,
  reducers: {
    setModalSection: (state, action) => {
      state.modalSection =
        state.modalSection === action.payload || action.payload === ''
          ? ''
          : action.payload;
    },
    setSelectedChart: (state, action) => {
      state.selectedChart = action.payload;
    },
    setSelecetedTimeFilter: (state, action) => {
      state.selectedTimeFilter = action.payload;
    },
    setSelectedCoin: (state, action) => {
      state.selectedCoin = action.payload;
    },
    setBetAmount: (state, action) => {
      state.betAmount = action.payload;
    },
    setMarketInfo: (state, action) => {
      state.marketInfo = action.payload;
      state.myTrade = { bull: 0, bear: 0 };
    },
    setJoinedGroup: (state, action) => {
      state.joinedGroup = action.payload;
    },
    setMarketRatio: (state, action) => {
      if (action.payload) {
        let bearRatio, bullRatio;
        let { bull_market_pool, bear_market_pool, misc } = action.payload;
        const losingPercentage = state.selectedCoin.losing_pot_percentage;
        const guaranteedPot = misc.minimum_pot_percentage / 100;
        bull_market_pool = bull_market_pool ? bull_market_pool : 0;
        bear_market_pool = bear_market_pool ? bear_market_pool : 0;

        if (!bull_market_pool && !bear_market_pool) {
          bearRatio = misc.minimum_payout_ratio;
          bullRatio = misc.minimum_payout_ratio;
        } else if (bear_market_pool < bull_market_pool * guaranteedPot) {
          bearRatio = 1 + bull_market_pool;
          bullRatio = calculateRatio(
            bull_market_pool * guaranteedPot,
            bull_market_pool,
            losingPercentage
          );
        } else if (bull_market_pool < bear_market_pool * guaranteedPot) {
          bearRatio = calculateRatio(
            bear_market_pool * guaranteedPot,
            bear_market_pool,
            losingPercentage
          );
          bullRatio = 1 + bear_market_pool;
        } else if (bull_market_pool && bear_market_pool) {
          bearRatio = calculateRatio(
            bull_market_pool,
            bear_market_pool,
            losingPercentage
          );
          bullRatio = calculateRatio(
            bear_market_pool,
            bull_market_pool,
            losingPercentage
          );
        }
        // console.log(bearRatio, '====bear', bullRatio, '====bull');

        state.marketRatio = { bear: bearRatio, bull: bullRatio };
      }
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
      if (action.payload.my_trades) state.myTrade = action.payload.my_trades;
    },
    updateGroupList: (state, action) => {
      state.tradeGroups = action.payload;
      const idx = action.payload.findIndex(
        (g) => g._id === state.selectedGroup._id
      );
      state.selectedGroup = action.payload[idx];
    },
    updateCoinList: (state, action) => {
      const options = action.payload.map((option) => {
        return {
          ...option.coin_info,
          ...option,
        };
      });
      state.availableCoins = options;
    },
    setMyTrade: (state, action) => {
      if (
        action.payload &&
        action.payload.trade_group_id === state.selectedGroup._id
      ) {
        state.myTrade = action.payload;
        const idx = state.tradeGroups.findIndex(
          (g) => g._id === action.payload.trade_group_id
        );
        if (idx !== -1) {
          state.tradeGroups[idx].my_trades = action.payload;
        }
      } else {
        state.tradeGroups = state.tradeGroups.map((g) => {
          return { ...g, my_trades: { bear: 0, bull: 0 } };
        });
        state.myTrade = { bear: 0, bull: 0 };
      }
    },
    resetTradingState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBinaryOptionList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBinaryOptionList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload.binary_option_list.length) {
          state.availableOptions = payload.binary_option_list;

          const options = payload.binary_option_list.map((option) => {
            return {
              ...option.coin_info,
              ...option,
            };
          });
          state.availableCoins = options;

          if (
            window.location.pathname === '/trade' ||
            window.location.pathname === '/trade/'
          ) {
            const newRoute = `/trade/${payload.binary_option_list[0]._id}`;
            window.location.href = newRoute;
          }
        }
      })
      .addCase(fetchBinaryOptionList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchBinaryOptionDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBinaryOptionDetail.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload.binary_option) {
          const { coin_info, banner, trade_group, market_info } =
            payload.binary_option;
          const coin = {
            ...coin_info,
            ...payload.binary_option,
          };
          state.selectedCoin = coin;
          state.selectedBanner = banner.status === true ? banner : null;
          state.joinedGroup = false;
          state.tradeGroups = trade_group;
          state.selectedGroup = trade_group[0];
          if (trade_group[0].my_trades) {
            state.myTrade = trade_group[0].my_trades;
          }
          state.marketInfo = market_info;
          state.betAmount = trade_group[0].min_trade;
        }
      })
      .addCase(fetchBinaryOptionDetail.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (payload === 'Binary option not found') {
          window.location.href = '/trade';
        }
      })
      .addCase(tradeOption.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(tradeOption.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(tradeOption.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setModalSection,
  setSelectedChart,
  setSelecetedTimeFilter,
  setSelectedCoin,
  setSelectedGroup,
  setBetAmount,
  setJoinedGroup,
  setMarketInfo,
  setMarketRatio,
  setMyTrade,
  resetTradingState,
  updateCoinList,
  updateGroupList,
} = trade.actions;

export default trade.reducer;
