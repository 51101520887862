import moment from 'moment';
import SecondaryButton from '../Button/SecondaryButton';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { thousandSeparator } from '../../utils/math';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import EditBio from './EditBio';
import ActionButton from '../Button/ActionButton';
import Logout from '../Modal/Logout';

const BasicInfo = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [showEditBio, setShowEditBio] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [, setSearchParams] = useSearchParams();

  const fields = [
    {
      placeholder: 'Email',
      field: 'email',
    },
    // {
    //   placeholder: 'Phone',
    //   field: 'phone_number',
    // },
    {
      placeholder: 'Trader Status',
      field: 'status',
    },
    {
      placeholder: 'Registered Date',
      field: 'createdAt',
    },
    {
      placeholder: 'CKU Wallet Balance',
      field: 'lucky_coin_balance',
    },
    {
      placeholder: 'Amount Purchase',
      field: 'total_trades',
    },
    {
      placeholder: 'Win History',
      field: 'winning_trades',
    },
    {
      placeholder: 'Referral Link',
      field: 'referral_tag',
    },
    {
      placeholder: "Trader's CKU Wallet",
      field: 'external_wallet_list',
    },
  ];

  return (
    <>
      {showEditBio ? <EditBio closeModal={setShowEditBio} /> : null}
      {showLogout ? <Logout closeModal={setShowLogout} /> : null}
      <div className="flex flex-col sm:w-[50%]">
        <div className="flex flex-row justify-between items-center mb-[14px]">
          <p className="text-blue font-poppins-semibold">Basic Info</p>
          <SecondaryButton
            title="Edit Account"
            onClick={() => setShowEditBio(true)}
            classes="text-blue rounded-lg border-[0.5px] border-blue !text-base !bg-white"
          />
        </div>
        {userInfo ? (
          <div className="flex flex-col">
            {fields.map((field, idx) => (
              <div
                className="flex flex-row items-center w-full mb-[8px] text-sm"
                key={idx}
              >
                <label className="w-[45%] lg:w-[25%] font-poppins-semibold">
                  {field.placeholder}
                </label>
                <p
                  className={`w-[48%] lg:w-[25%] truncate
                ${
                  field.field === 'total_trades' ||
                  field.field === 'winning_trades'
                    ? 'underline cursor-pointer'
                    : ''
                }
              `}
                  onClick={() => {
                    if (
                      field.field === 'total_trades' ||
                      field.field === 'winning_trades'
                    ) {
                      setSearchParams({ section: 'trading' });
                    }
                  }}
                >
                  {field.field === 'createdAt'
                    ? moment(userInfo.createdAt).format('DD-MM-YYYY, HH:mm:ss')
                    : field.field === 'lucky_coin_balance'
                    ? `CKU ${thousandSeparator(userInfo[field.field])}`
                    : field.field === 'winning_trades'
                    ? userInfo[field.field].length
                    : field.field === 'external_wallet_list'
                    ? userInfo[field.field] && userInfo[field.field].length
                      ? 'Connected'
                      : 'Not Connected'
                    : userInfo[field.field]}
                </p>
                {field.field === 'referrer_tag' && userInfo[field.field] ? (
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(userInfo.referrer_tag);
                      enqueueSnackbar('Sucessfully copied referral link', {
                        variant: 'success',
                      });
                    }}
                    className="flex ml-[5px]"
                  >
                    <ion-icon
                      name="copy-outline"
                      style={{ width: '18px', height: '18px' }}
                    ></ion-icon>
                  </button>
                ) : null}
              </div>
            ))}
            <ActionButton
              title="Logout"
              onClick={() => setShowLogout(true)}
              classes="!py-[8px] !w-[50%] sm:!w-[20%] !bg-red !w-fit !text-base mt-[12px]"
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default BasicInfo;
