import LayoutUser from '../components/Layout/LayoutUser';
import ActionButton from '../components/Button/ActionButton';
import DeviceBanner from '../assets/imgs/device-banner.png';
import TradeExp1 from '../assets/imgs/trade-exp-1.png';
import TradeExp2 from '../assets/imgs/trade-exp-2.png';
import TradeExp3 from '../assets/imgs/trade-exp-4.png';
import TradeReturn from '../assets/imgs/trade-return.png';
import TradeBg from '../assets/imgs/trade-background.png';
import GlobeIcon from '../assets/imgs/globe.png';
import LatokenLogo from '../assets/imgs/latoken-logo.png';
import { useIsLoggedIn } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRedirectedToTrade } from '../slices/commonSlice';
import 'aos/dist/aos.css';

const HomePage = () => {
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();
  const redirected = useSelector((state) => state.common.redirectedToTrade);
  const dispatch = useDispatch();

  const AdvantageCard = ({ iconName, title, subtile }) => {
    return (
      <article
        data-aos="fade-up"
        data-aos-duration="800"
        className="min-w-[65%] sm:min-w-[45%] sm:max-w-[45%] flex flex-col py-[15px] px-[21px] bg-[rgba(255,255,255,0.10)] shadow-card rounded-2xl border-b-2 border-b-[rgba(255,255,255,0.50)] border-l-2 border-l-[rgba(255,255,255,0.50)]"
      >
        <ion-icon
          name={iconName}
          style={{ width: '24px', height: '24px', color: '#007AFF' }}
        ></ion-icon>
        <h3 className="text-white-f mt-[56px] mb-[6px]">{title}</h3>
        <p className="text-lg text-[#9CB1C2]">{subtile}</p>
      </article>
    );
  };

  useEffect(() => {
    if (isLoggedIn && !redirected) {
      dispatch(setRedirectedToTrade(true));
      navigate('/trade');
    }
  }, [dispatch, isLoggedIn, navigate, redirected]);

  return (
    <LayoutUser>
      <section className="flex flex-col bg-white-f py-[60px] sm:pb-[120px] sm:pt-0 pb-[30px] px-[20px] sm:px-[10%]">
        <div className="flex flex-col sm:flex-row-reverse sm:justify-between sm:items-center">
          <div
            className="w-full h-[40vh] sm:w-[55%] sm:h-[90vh] mb-[10px]"
            data-aos="fade-left"
            data-aos-duration="800"
          >
            <img
              src={DeviceBanner}
              alt="device-banner"
              className="w-full h-full object-contain"
            />
          </div>
          <div
            className="sm:w-[50%] text-center sm:text-left"
            data-aos="fade-right"
            data-aos-duration="800"
          >
            <h2 className="leading-tight sm:text-[48px] font-poppins-bold">
              Unleash Trading Skills
            </h2>
            <h2 className="text-blue leading-tight sm:text-[48px] font-poppins-bold">
              Real-Time Trading Simulation Powered by Tradingview
            </h2>
            <p className="text-lg sm:text-[26px] text-[#404059] my-[15px]">
              Become the Top-Trader on Duotrx to win real world prizes in USDT.
            </p>
            <ActionButton
              title="Start Playing"
              onClick={() => navigate(isLoggedIn ? '/trade' : '/login')}
              classes="sm:!px-[50px] sm:inline-block sm:w-auto"
            />
          </div>
        </div>
        <div className="py-[30px] sm:py-0 flex flex-col sm:flex-row sm:justify-between sm:items-center">
          <h3
            className="font-poppins leading-tight text-center sm:text-left w-full sm:w-[20%]"
            data-aos="fade-right"
          >
            Elevate Your <br /> Trading Experience
          </h3>
          <div
            className="flex flex-col sm:flex-row mt-[12px] sm:w-[70%]"
            data-aos="fade-left"
          >
            <div className="flex flex-row items-center sm:gap-[35px] gap-[16px] w-[80%] m-auto p-[15px] sm:py-0 border-b sm:border-b-0 sm:border-r sm:border-r-black-100 border-b-black-100">
              <figure className="w-[80px] h-[92px]">
                <img
                  src={TradeExp1}
                  alt="trade-exp-1"
                  className="w-full h-full object-contain"
                />
              </figure>
              <span className="flex flex-col">
                <p className="text-blue font-poppins-semibold text-[22px]">
                  Execution
                </p>
                <p className="text-lg">1 Min</p>
              </span>
            </div>
            <div className="flex flex-row items-center sm:gap-[35px] gap-[16px] w-[80%] m-auto p-[15px] sm:py-0 border-b sm:border-b-0 sm:border-r sm:border-r-black-100 border-b-black-100">
              <figure className="w-[80px] h-[87px]">
                <img
                  src={TradeExp2}
                  alt="trade-exp-2"
                  className="w-full h-full object-contain"
                />
              </figure>
              <span className="flex flex-col">
                <p className="text-blue font-poppins-semibold text-[22px]">
                  Zero
                </p>
                <p className="text-lg">Spread</p>
              </span>
            </div>
            <div className="flex flex-row items-center sm:gap-[35px] gap-[16px] w-[80%] m-auto p-[15px] sm:py-0">
              <figure className="w-[120px] h-[120px]">
                <img
                  src={TradeExp3}
                  alt="trade-exp-3"
                  className="w-full h-full object-contain"
                />
              </figure>
              <span className="flex flex-col">
                <p className="text-blue font-poppins-semibold text-[22px]">
                  Short Sell
                </p>
                <p className="text-lg">Instant Short Contract Creation</p>
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="sm:relative bg-[#002] sm:bg-transparent">
        <img
          src={TradeBg}
          alt="trade-bg"
          className="w-full h-full z-[-1] object-cover sm:absolute sm:block hidden top-0 left-0"
        />
        <div className="flex flex-col sm:flex-row sm:items-center py-[60px] px-[20px]">
          <figure
            className="w-full h-[50vh] sm:h-[80vh] sm:w-[45%]"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            <img
              src={TradeReturn}
              alt="trade-return"
              className="w-full h-full object-contain"
            />
          </figure>
          <div className="sm:flex sm:flex-row sm:items-center sm:w-[50%]">
            <article
              data-aos="fade-down"
              className="flex flex-col sm:justify-between sm:h-[400px] sm:w-[50%] sm:mr-[18px] bg-white-f rounded-[10px] py-[12px] px-[20px]"
            >
              <img
                src={GlobeIcon}
                alt="globe-icon"
                className="w-[24px] h-[24px]"
              />
              <div>
                <h3 className="w-[45%] mt-[24px] mb-[8px] text-blue">
                  130+ countries
                </h3>
                <p className="text-lg text-[#404059]">
                  Prizes are paid out in USDT and BTC. Traders can redeem their prizes from anywhere in the world.
                </p>
              </div>
            </article>
            <div className="flex flex-row sm:flex-wrap gap-[12px] max-w-none sm:max-w-full overflow-auto mt-[18px] sm:mt-0">
              <AdvantageCard
                iconName="person-outline"
                title="150K+"
                subtile="Traders accounts"
              />
              <AdvantageCard
                iconName="swap-horizontal-outline"
                title="3M+"
                subtile="Monthly transactions"
              />
              <AdvantageCard
                iconName="person-outline"
                title="USDT 500K+"
                subtile="Average monthly prize payouts"
              />
              <AdvantageCard
                iconName="wallet-outline"
                title="CKU 211M"
                subtile="Monthly trade turnover"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-[#040621] py-[60px] px-[16px] flex flex-col sm:flex-row-reverse sm:gap-[5%] sm:px-[5%]">
        <div className="flex flex-col gap-[24px]" data-aos="fade-up">
          <div className="w-[70%] h-[42px]">
            <img
              src={LatokenLogo}
              alt="latoken-logo"
              className="w-full h-full object-contain object-left"
            />
          </div>
          <article className="text-white-f">
            <h4 className="text-lg">High Security</h4>
            <p className="text-lg">
              We protect mobile users data as well as the web and use all
              security's best practices
            </p>
          </article>
          <article className="text-white-f">
            <h4 className="text-lg">Alerts new listings or prices</h4>
            <p className="text-lg">
              Setup price alerts - hold your hand on the pulse of the market and
              never miss new opportunities.
            </p>
          </article>
          <article className="text-white-f">
            <h4 className="text-lg">Intuitive Interface</h4>
            <p className="text-lg">Buy, Transfer and Trade.</p>
          </article>
          <article className="text-white-f">
            <h4 className="text-lg">Localization</h4>
            <p className="text-lg">
              Trade Bitcoin in English, Spanish, France, Italiano, Indian,
              Korean and Indonesian (Bahasa) languages.
            </p>
          </article>
          <article className="text-white-f">
            <h4 className="text-lg">Chat Support 24/7</h4>
            <p className="text-lg">
              24/7 live chat support in the app will help you find a solution to
              your problems.
            </p>
          </article>
        </div>
        <div
          className="flex flex-col gap-[16px] mt-[24px]"
          data-aos="fade-down"
        >
          <h4 className="text-blue">
            Get CKU - <br />
            <span
              className="text-white-f"
              onClick={() => navigate(isLoggedIn ? '/trade' : '/login')}
            >
              Start Playing Today!
            </span>
          </h4>
          <p className="text-lg text-[rgba(255,255,255,0.50)]">
            Unleash Your Trading Potential with CKU: Apply Your Strategy and Win
            Big!
          </p>
          <ActionButton
            title="Start Playing"
            onClick={() => navigate(isLoggedIn ? '/trade' : '/login')}
            classes="mt-[8px] sm:!w-[60%]"
          />
        </div>
      </section>
    </LayoutUser>
  );
};

export default HomePage;
