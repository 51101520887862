const SecondaryButton = ({ type = 'button', classes, title, ...props }) => {
  return (
    <button
      type={type}
      className={`bg-[#F6F6F6] py-[8px] px-[16px] font-poppins-semibold text-lg rounded-lg text-center w-fit ${classes}`}
      {...props}
    >
      {title}
    </button>
  );
};

export default SecondaryButton;
