import { useState } from 'react';
import ActionButton from '../Button/ActionButton';
import SecondaryButton from '../Button/SecondaryButton';
import SideBar from '../Navbar/SideBar';
import Wallet from '../Modal/Wallet';
import CurrencySelect from '../Trade/CurrencySelect';
import { useLocation } from 'react-router-dom';
import { thousandSeparator } from '../../utils/math';
import { useSelector } from 'react-redux';

const LayoutTrade = ({ children }) => {
  const [showWallet, setShowWallet] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const location = useLocation();

  return (
    <>
      {showWallet ? <Wallet closeModal={setShowWallet} /> : null}
      <section className="bg-white-f w-full h-full flex sm:flex-row flex-col-reverse">
        <SideBar />
        <div className="flex flex-col w-full sm:w-[calc(100vw-81px)] h-full">
          <div
            className={`flex flex-row w-full sm:gap-[24px] p-[14px] justify-between`}
          >
            {location.pathname.includes('/trade') ? (
              <span className="hidden sm:block">
                <CurrencySelect width="w-fit" />
              </span>
            ) : null}
            <span
              className={`flex flex-row items-center justify-between sm:justify-end w-full gap-[12px] ${
                location.pathname.includes('/trade') ? 'sm:w-[50%]' : ''
              }`}
            >
              <SecondaryButton
                title={`CKU ${thousandSeparator(
                  userInfo && userInfo.lucky_coin_balance
                    ? userInfo.lucky_coin_balance
                    : 0
                )}`}
                classes="!py-[12px]"
              />
              <ActionButton
                iconName="wallet"
                iconSize={24}
                iconPos="left"
                title="Get CKU"
                classes={`!px-[8px] !h-fit !py-[12px] !w-fit !rounded-lg ${
                  location.pathname.includes('/trade')
                    ? 'sm:!w-[27%]'
                    : 'sm:!w-[13.5%]'
                }`}
                onClick={() => setShowWallet(true)}
              />
            </span>
          </div>
          <div className="sm:max-h-[calc(100vh-76px)] sm:overflow-auto pb-[70px] sm:pb-0">
            {children}
          </div>
        </div>
      </section>
    </>
  );
};

export default LayoutTrade;
