import { createSlice } from '@reduxjs/toolkit';
import {
  fetchPlayerFriendsList,
  fetchPlayerTradeHistory,
  fetchPlayerTransactionDetail,
  fetchPlayerTransactionHistory,
} from '../actions/player';

const initialState = {
  profileSections: [
    {
      name: 'Basic Info',
      query: 'info',
    },
    {
      name: 'Transaction History',
      query: 'transaction',
    },
    {
      name: 'Trading History',
      query: 'trading',
    },
    {
      name: 'Referral',
      query: 'referral',
    },
  ],
  selectedSection: 'info',
  isLoading: false,
  transactionHistory: [],
  tradeHistory: [],
  friendsList: [],
  trsPageData: {
    search: '',
    dataLimit: 10,
    page: 0,
  },
  tradePageData: {
    search: '',
    dataLimit: 10,
    page: 0,
  },
  friendsPageData: {
    search: '',
    dataLimit: 10,
    page: 0,
  },
  trsTotalPages: 0,
  tradeTotalPages: 0,
  friendsTotalPages: 0,
  invoice: null,
};

export const player = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
    },
    setTrsPageData: (state, action) => {
      state.trsPageData = { ...state.trsPageData, ...action.payload };
    },
    setFriendsPageData: (state, action) => {
      state.friendsPageData = { ...state.friendsPageData, ...action.payload };
    },
    setTradePageData: (state, action) => {
      state.tradePageData = { ...state.tradePageData, ...action.payload };
    },
  },
  extraReducers: {
    [fetchPlayerTransactionHistory.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPlayerTransactionHistory.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.transactionHistory = payload.transaction_list;
      state.trsTotalPages = payload.pages;
    },
    [fetchPlayerTransactionHistory.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchPlayerTradeHistory.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPlayerTradeHistory.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.tradeHistory = payload.trade_list;
      state.tradeTotalPages = payload.pages;
    },
    [fetchPlayerTradeHistory.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchPlayerTransactionDetail.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPlayerTransactionDetail.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const { transaction } = payload;
      const ticketList = transaction.ticket_list.reduce((acc, obj) => {
        acc[obj.drawdown.id] = acc[obj.drawdown.id] || [];
        acc[obj.drawdown.id].push(obj);
        return acc;
      }, {});

      const transformedTicketList = Object.entries(ticketList).map(
        ([key, value]) => ({ [key]: value })
      );
      // console.log(transformedTicketList, '====ticket-list');
      transaction.ticket_list = transformedTicketList;
      state.invoice = payload.transaction;
    },
    [fetchPlayerTransactionDetail.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchPlayerFriendsList.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPlayerFriendsList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.friendsList = payload.referral_list;
      state.friendsTotalPages = payload.pages;
    },
    [fetchPlayerFriendsList.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTradePageData,
  setTrsPageData,
  setFriendsPageData,
  setSelectedSection,
} = player.actions;

export default player.reducer;
