import moment from 'moment-timezone';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const TradingViewWidget = () => {
  const widget = useRef();
  const { selectedCoin } = useSelector((state) => state.trade);

  useEffect(() => {
    if (selectedCoin) {
      const script = document.createElement('script');
      script.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
      script.type = 'text/javascript';
      script.async = true;
      script.innerHTML = `
        {
          "autosize": true,
          "symbol": "BITSTAMP:${selectedCoin.currency}USD",
          "interval": "1",
          "timezone": "${moment.tz.guess()}",
          "theme": "light",
          "style": "1",
          "locale": "en",
          "enable_publishing": false,
          "save_image": false,
          "hide_volume": true,
          "support_host": "https://www.tradingview.com"
        }`;

      if (widget.current.hasChildNodes()) {
        widget.current.replaceChildren(script);
      } else {
        widget.current.appendChild(script);
      }
    }
  }, [selectedCoin]);

  return (
    <div
      className="w-full h-full sm:px-[12px] py-[10px] sm:py-0"
      ref={widget}
    ></div>
  );
};

export default TradingViewWidget;
