import { Modal as ModalMUI } from '@mui/material';
import { useDispatch } from 'react-redux';

const Modal = ({
  title = '',
  closeModal,
  hideCloseBtn = false,
  width = '',
  closeWithDispatch = false,
  children,
}) => {
  const dispatch = useDispatch();
  const handleClose = () =>
    closeWithDispatch ? dispatch(closeModal('')) : closeModal(false);

  return (
    <ModalMUI open={true} onClose={handleClose}>
      <div
        className={`${width} w-[85%] sm:w-[25%] absolute z-100 bg-white-f p-[24px] rounded-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-thick`}
      >
        <div
          className={`flex flex-row justify-between items-center border-b border-b-black-100 pb-[24px] ${
            !title && hideCloseBtn ? '!border-b-0 !pb-0' : ''
          }`}
        >
          {title ? (
            <h3 className="text-lg font-poppins-bold">{title}</h3>
          ) : null}
          {!hideCloseBtn ? (
            <button className="bg-transparent" onClick={handleClose}>
              <ion-icon
                name="close-circle"
                style={{ width: '20px', height: '20px' }}
              ></ion-icon>
            </button>
          ) : null}
        </div>
        <section
          className={`mt-[24px] max-h-[60vh] overflow-auto ${
            !title && hideCloseBtn ? '!mt-0' : ''
          }`}
        >
          {children}
        </section>
      </div>
    </ModalMUI>
  );
};

export default Modal;
