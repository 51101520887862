import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../utils/API';
import { enqueueSnackbar } from 'notistack';

export const playerLogin = createAsyncThunk(
  'auth/login',
  async (loginData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('webLogin', loginData);
      if (data.token) localStorage.setItem('USER_KEY', data.token);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchPlayerInfo = createAsyncThunk(
  'auth/fetchPlayerInfo',
  async (player, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('traderInfo', {});
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const registerPlayerStep1 = createAsyncThunk(
  'auth/registerPlayerStep1',
  async (playerData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('registerTraderStep1', playerData);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const registerPlayerStep2 = createAsyncThunk(
  'auth/registerPlayerStep2',
  async (playerData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('registerTraderStep2', playerData);
      if (data.token) localStorage.setItem('USER_KEY', data.token);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const registerPlayerResendOTP = createAsyncThunk(
  'auth/registerPlayerResendOTP',
  async (playerData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post(
        'registerTraderResendOTP',
        playerData
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const checkReferrer = createAsyncThunk(
  'auth/checkReferrer',
  async (referrerData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('checkReferrer', referrerData);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addReferrer = createAsyncThunk(
  'auth/addReferrer',
  async (referrerData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.put('addReferrer', referrerData);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const forgotPasswordStep1 = createAsyncThunk(
  'auth/forgotPasswordStep1',
  async (email, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('traderForgetPasswordStep1', email);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const forgotPasswordStep2 = createAsyncThunk(
  'auth/forgotPasswordStep2',
  async (playerData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post(
        'traderForgetPasswordStep2',
        playerData
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const forgotPasswordStep3 = createAsyncThunk(
  'auth/forgotPasswordStep3',
  async (newPasswordData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post(
        'traderForgetPasswordStep3',
        newPasswordData
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendEmailMessage = createAsyncThunk(
  'auth/sendEmailMessage',
  async (messageData, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.post('webSendEmail', messageData);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
