import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import LayoutTrade from '../components/Layout/LayoutTrade';
import { setSelectedSection } from '../slices/playerSlice';
import BasicInfo from '../components/Profile/BasicInfo';
import TrsHistory from '../components/History/TrsHistory';
import TicketHistory from '../components/History/TicketHistory';
import Referral from '../components/Profile/Referral';

const ProfilePage = () => {
  const { profileSections, selectedSection } = useSelector(
    (state) => state.player
  );
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const sectionQuery = searchParams.get('section');
    if (sectionQuery) dispatch(setSelectedSection(sectionQuery));
  }, [dispatch, searchParams]);

  return (
    <LayoutTrade>
      <div className="w-full flex flex-row max-w-full overflow-auto border-b-black-60 border-b">
        {profileSections.map((section) => (
          <button
            className={`${
              selectedSection === section.query ? 'opacity-100' : 'opacity-40'
            } py-[12px] min-w-fit px-[16px] text-blue font-poppins-semibold last:border-r-0 border-r-2 border-r-blue`}
            key={section.name}
            onClick={() => setSearchParams({ section: section.query })}
          >
            {section.name}
          </button>
        ))}
      </div>
      <div className="py-[24px] px-[16px] w-full">
        {selectedSection === 'info' ? (
          <BasicInfo />
        ) : selectedSection === 'transaction' ? (
          <TrsHistory />
        ) : selectedSection === 'trading' ? (
          <TicketHistory />
        ) : selectedSection === 'referral' ? (
          <Referral />
        ) : null}
      </div>
    </LayoutTrade>
  );
};

export default ProfilePage;
