import { useDispatch, useSelector } from 'react-redux';
import {
  setBetAmount,
  setJoinedGroup,
  setModalSection,
  setSelectedGroup,
} from '../../slices/tradeSlice';
import { formatNumberWithSuffix } from '../../utils/math';

const TradeGroup = ({ width = 'w-full' }) => {
  const { modalSection, tradeGroups, selectedGroup } = useSelector(
    (state) => state.trade
  );
  const dispatch = useDispatch();

  const TradeGroupList = () => {
    return (
      <div className="absolute top-[105%] left-0 gap-[10px] z-20 w-full max-h-[30vh] overflow-auto flex flex-col bg-[rgba(255,255,255,0.10)] backdrop-blur-md rounded-lg border border-[#DADADA] p-[12px]">
        {tradeGroups.map((group) => (
          <span
            className="flex flex-col text-sm"
            key={group._id}
            onClick={() => {
              if (selectedGroup._id !== group._id) {
                dispatch(setJoinedGroup(false));
                dispatch(setSelectedGroup(group));
                dispatch(setBetAmount(group.min_trade));
              }
            }}
          >
            <p
              className={`${
                group._id === selectedGroup._id
                  ? 'font-poppins-semibold'
                  : 'font-poppins'
              }`}
            >
              {group.name}
            </p>
            <p className="text-[#70808C]">
              {formatNumberWithSuffix(group.min_trade)} -{' '}
              {formatNumberWithSuffix(group.max_trade)}
            </p>
          </span>
        ))}
      </div>
    );
  };

  if (tradeGroups && tradeGroups.length && selectedGroup) {
    return (
      <div
        className={`relative flex flex-col cursor-pointer bg-white-btn py-[12px] px-[6px] rounded-lg ${width}`}
        onClick={() => dispatch(setModalSection('TRADER_GROUP'))}
      >
        <span className="flex flex-row justify-between items-center text-[10px] text-[#70808C]">
          <p>Trade Group</p>
          <p>
            {formatNumberWithSuffix(selectedGroup.min_trade)} -{' '}
            {formatNumberWithSuffix(selectedGroup.max_trade)}
          </p>
        </span>
        <span className="flex flex-row justify-between items-center text-sm">
          <p>{selectedGroup.name}</p>
          <ion-icon
            name={
              modalSection === 'TRADER_GROUP'
                ? 'chevron-up-outline'
                : 'chevron-down-outline'
            }
            className="w-[8px] h-[8px] sm:w-[16px] sm:h-[16px]"
          ></ion-icon>
        </span>
        {modalSection === 'TRADER_GROUP' ? <TradeGroupList /> : null}
      </div>
    );
  }
};

export default TradeGroup;
