import LayoutUser from '../components/Layout/LayoutUser';
import SecondaryButton from '../components/Button/SecondaryButton';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <LayoutUser is404={true}>
      <div className="flex flex-col items-center sm:flex-row-reverse sm:justify-center w-full gap-[8px] px-[16px] py-[32px] sm:h-[70vh] bg-white-f">
        <div className="flex flex-col sm:w-[40%] items-center justify-center gap-[8px]">
          <h2
            className="text-[96px] sm:text-[128px] text-white-f font-poppins-extrabold"
            style={{
              textShadow: '4px 4px 0px #000',
              WebkitTextStroke: '1px #000',
            }}
          >
            Oops!
          </h2>
          <h5 className="text-lg font-poppins-bold">404 PAGE NOT FOUND</h5>
          <p className="text-center">
            The page you are attempting to access may have been removed,
            undergone a name change, or is currently temporarily unavailable.
          </p>
          <div className="flex flex-row gap-[8px]">
            <SecondaryButton
              title="REFRESH"
              type="button"
              classes="bg-transparent text-black-100 !w-[160px]"
              onClick={() => window.location.reload()}
            />
            <SecondaryButton
              title="HOME"
              type="button"
              classes="!bg-black-100 text-white !w-[160px]"
              onClick={() => navigate('/')}
            />
          </div>
        </div>
      </div>
    </LayoutUser>
  );
};

export default NotFoundPage;
