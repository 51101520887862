import SideLogo from '../../assets/imgs/side-logo.png';
import TradeLogo from '../../assets/icons/trade.png';
import HelpLogo from '../../assets/icons/help.png';
import ProfileLogo from '../../assets/icons/profile.png';
import { useLocation, useNavigate } from 'react-router-dom';

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const SideMenuBtn = ({ icon, name, page }) => {
    return (
      <button
        className={`flex flex-col items-center text-center text-[10px] text-[#9CB0C2] ${
          location.pathname.includes(page) ? 'opacity-100' : 'opacity-50'
        }`}
        onClick={() => {
          navigate(page);
        }}
      >
        <img src={icon} alt={`${name}-icon`} className="w-[24px] h-[24px]" />
        {name}
      </button>
    );
  };

  return (
    <>
      <div className="fixed z-20 sm:relative bottom-0 w-[100vw] sm:w-fit sm:h-[100vh] bg-[#F6F6F6] py-[15px] sm:py-[24px] px-[30px] sm:px-[12px] flex flex-row sm:flex-col items-center justify-between">
        <div className="flex flex-row justify-between w-full sm:justify-normal sm:flex-col gap-[32px]">
          <div
            className="w-[57px] h-[41px] hidden sm:block cursor-pointer"
            onClick={() => navigate('/')}
          >
            <img
              src={SideLogo}
              alt="logo"
              className="w-full h-full object-contain"
            />
          </div>
          <SideMenuBtn name="Trades" page="/trade" icon={TradeLogo} />
          <SideMenuBtn name="Profile" page="/profile" icon={ProfileLogo} />
          <SideMenuBtn name="Help" page="/contact" icon={HelpLogo} />
        </div>
      </div>
    </>
  );
};

export default SideBar;
