import { Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import Aos from 'aos';
import { store } from './store';
import LoginPage from './pages/Login';
import HomePage from './pages/Home';
import RegisterPage from './pages/Register';
import ProfilePage from './pages/Profile';
import FAQPage from './pages/FAQ';
import AboutPage from './pages/About';
import PrivateRoutes from './components/Route/PrivateRoutes';
import ContactPage from './pages/Contact';
import { useEffect } from 'react';
import { getUSDPrice } from './actions/common';
import { jakartaCurrentTime } from './utils/date';
import NotFoundPage from './pages/NotFoundPage';
import TradePage from './pages/Trade';
import ReferralPage from './pages/Referral';

function App() {
  useEffect(() => {
    Aos.init();
    store.dispatch(getUSDPrice()).then((res) => {
      const { payload } = res;
      if (payload.current_date && payload.duration) {
        const date = new Date(payload.current_date);
        jakartaCurrentTime(
          payload.duration >= 1
            ? date.setSeconds(date.getSeconds() + Math.floor(payload.duration))
            : date
        );
      }
    });
  }, []);

  return (
    <Provider store={store}>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<RegisterPage />} />
        <Route path="/signup/:id" element={<RegisterPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/referral" element={<ReferralPage />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/trade" element={<TradePage />} />
          <Route path="/trade/:id" element={<TradePage />} />
          <Route path="/profile" element={<ProfilePage />} />
        </Route>
        <Route path="*" title="ERROR 404" element={<NotFoundPage />} />
      </Routes>
    </Provider>
  );
}

export default App;
