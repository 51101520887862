import ActionButton from '../Button/ActionButton';
import WalletCard from '../Wallet/WalletCard';
import Modal from './Modal';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import ConnectWallet from './ConnectWallet';

const WalletList = ({ closeModal }) => {
  const { walletList } = useSelector((state) => state.wallet);
  const [showNewWallet, setShowNewWallet] = useState(false);

  return (
    <Modal closeModal={closeModal} title="List of External Wallet Address">
      {showNewWallet ? <ConnectWallet closeModal={setShowNewWallet} /> : null}
      <div className="flex flex-col">
        {walletList.map((wallet, idx) => (
          <div key={wallet.wallet_address}>
            <WalletCard walletInfo={wallet} />
          </div>
        ))}
      </div>
      <div className="p-[10px] flex flex-col border border-black-100 my-[16px] bg-light-yellow">
        <p className="font-poppins-semibold text-sm">Note:</p>
        <p className="text-sm">
          Please ensure your Latoken account is active. Blockchain we use is{' '}
          <span className="font-poppins-semibold">ERC20</span>.
        </p>
      </div>
      <ActionButton
        title="Add New Wallet"
        classes="!py-[8px] !rounded-lg"
        onClick={() => setShowNewWallet(true)}
      />
    </Modal>
  );
};

export default WalletList;
