import * as yup from 'yup';

export const loginUser = yup.object({
  email: yup
    .string()
    .email('Not a valid email address')
    .required('Email cannot be empty'),
  password: yup.string().required('Password cannot be empty'),
});

export const registerUser = yup.object({
  name: yup.string().required('Name cannot be empty'),
  email: yup
    .string()
    .email('Not a valid email address')
    .required('Email cannot be empty'),
  date_of_birth: yup.date().required('Birth date cannot be empty'),
  password: yup.string().required('Password cannot be empty'),
  confirm_password: yup.string().required('Password cannot be empty'),
  referral_code: yup.string(),
});

export const forgotPassEmail = yup.object({
  email: yup
    .string()
    .email('Not a valid email address')
    .required('Email cannot be empty'),
});

export const forgotPassOTP = yup.object({
  otp: yup.string().required('OTP cannot be empty'),
});

export const changePassword = yup.object({
  new_password: yup.string().required('Password cannot be empty'),
  confirm_new_password: yup
    .string()
    .oneOf([yup.ref('new_password'), null], `Password doesn't match`)
    .required('Confirm Password is required'),
});

export const dateRangeFilter = yup.object({
  startDate: yup.date(),
  endDate: yup.date(),
});

export const sendMessage = yup.object({
  title: yup.string().required('Title cannot be empty'),
  message: yup.string().required('Message cannot be empty'),
  email: yup
    .string()
    .email('Not a valid email address')
    .required('Email cannot be empty'),
});

export const connectWallet = yup.object({
  wallet_name: yup.string().required('Name cannot be empty'),
  email: yup
    .string()
    .email('Not a valid email address')
    .required('Email cannot be empty'),
  otp: yup.string().required('OTP cannot be empty'),
});

export const sendLC = yup.object({
  cku_amount: yup
    .number()
    .typeError('Amount should be filled')
    .required('Amount cannot be empty'),
  email: yup
    .string()
    .email('Not a valid email address')
    .required('Email cannot be empty'),
});

export const withdrawLC = yup.object({
  cku_amount: yup
    .number()
    .typeError('Amount should be filled')
    .required('Amount cannot be empty'),
  wallet_address: yup
    .string()
    .email('Not a valid email address')
    .required('Wallet address cannot be empty'),
});

export const editWallet = yup.object({
  _id: yup.string().required(),
  wallet_name: yup.string().required('Wallet name cannot be empty'),
  wallet_address: yup
    .string()
    .email('Not a valid email address')
    .required('Wallet address cannot be empty'),
  wallet_platform: yup.string().default('latoken'),
  is_deleted: yup.boolean().default(false),
  is_active: yup.boolean().default(false),
});
