import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPagesContent } from '../actions/common';
import Loading from '../components/Loading';
import LayoutUser from '../components/Layout/LayoutUser';
import { useSearchParams } from 'react-router-dom';

const AboutPage = () => {
  const { isLoading, contentList, contentBanner } = useSelector(
    (state) => state.common
  );
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    var contentSection = searchParams.get('content_section');
    if (contentSection) {
      if (contentSection.includes('Service')) {
        contentSection = 'Service & Terms';
      }
      dispatch(fetchPagesContent({ content_type: contentSection }));
    } else {
      dispatch(fetchPagesContent({ content_type: 'About Us' }));
    }
  }, [dispatch, searchParams]);

  return (
    <LayoutUser showTabMenu={true}>
      {isLoading ? <Loading /> : null}
      <main>
        <div className="h-[35vh] w-full">
          {contentBanner ? (
            <img
              src={contentBanner}
              className="w-full h-full object-cover"
              alt="About Us Banner"
            />
          ) : null}
        </div>
        <div className="flex flex-col px-[16px] pb-[16px] sm:w-[60%] sm:m-auto">
          {contentList.length ? (
            <div className="flex flex-col">
              {contentList.map((content) => (
                <div key={content._id}>
                  <h3 className="text-2xl font-poppins-semibold mt-[60px] mb-[16px]">
                    {content.title}
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: content.content }}
                  ></div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </main>
    </LayoutUser>
  );
};

export default AboutPage;
