import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../components/Input/Input';
import { registerUser } from '../utils/validationForm';
import getToastMessage from '../utils/toastMessage';
import { useIsLoggedIn } from '../hooks/useAuth';
import Loading from '../components/Loading';
import ActionButton from '../components/Button/ActionButton';
import InputPassword from '../components/Input/InputPassword';
import InputDate from '../components/Input/InputDate';
import {
  checkReferrer,
  registerPlayerResendOTP,
  registerPlayerStep1,
} from '../actions/auth';
import RegisterOTP from '../components/Modal/RegisterOTP';
import LayoutUser from '../components/Layout/LayoutUser';
import { CURRENT_TIME } from '../utils/date';
import BgRegister from '../assets/imgs/bg-register.png';

const RegisterPage = () => {
  const { isLoading, userInfo, referrerId } = useSelector(
    (state) => state.auth
  );
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState, control, getValues } =
    useForm({
      resolver: yupResolver(registerUser),
    });
  const [showRegisterOTP, setShowRegisterOTP] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [agreedTNC, setAgreedTNC] = useState(false);
  const { id } = useParams();

  const onSubmit = async (data) => {
    if (agreedTNC) {
      const done = await dispatch(registerPlayerStep1(data));
      if (done) {
        const { message, variant } = getToastMessage(done);
        const { payload } = done;
        if (variant === 'success') {
          enqueueSnackbar(message, { variant });
          if (payload && payload.status === 'Registration Pending') {
            const sendOTP = await dispatch(
              registerPlayerResendOTP({ email: getValues('email') })
            );
            if (sendOTP) {
              let { variant: variantOTP } = getToastMessage(sendOTP);
              if (variantOTP === 'success') setShowRegisterOTP(true);
            }
          } else {
            setShowRegisterOTP(true);
          }
        }
      }
    } else {
      enqueueSnackbar(`Please read and check the terms & conditions`, {
        variant: 'warning',
      });
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(checkReferrer({ referral_code: id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (referrerId) reset({ referral_code: referrerId });
  }, [referrerId, reset]);

  useEffect(() => {
    if (userInfo || isLoggedIn) {
      document.activeElement.blur();
      reset();
      setTimeout(() => {
        navigate('/');
      }, 200);
    }
  }, [navigate, reset, userInfo, isLoggedIn, enqueueSnackbar]);

  return (
    <LayoutUser>
      {isLoading ? <Loading /> : null}
      {showRegisterOTP ? (
        <RegisterOTP
          email={getValues('email')}
          closeModal={setShowRegisterOTP}
        />
      ) : null}
      <div className="flex flex-col w-full h-full sm:flex-row bg-white-f sm:justify-between">
        <div className="w-full h-[166px] sm:w-[55%] sm:h-[100vh] relative">
          <img
            src={BgRegister}
            alt="register-background"
            className="w-full h-full object-cover"
          />
          <div className="text-white-f w-[70%] absolute top-1/2 left-1/4 transform -translate-x-1/4 -translate-y-1/2">
            <h1 className="text-[24px] sm:text-[64px]">Become a Pro Trader</h1>
            <h2 className="text-lg sm:text-[32px]">
              A reliable trading platform is essential for success
            </h2>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center w-[80%] m-auto pb-[32px] sm:w-[35%]">
          <h2 className="text-lg font-poppins-semibold my-[32px]">Register</h2>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full mx-auto">
            <Input
              title="Name"
              placeholder="Name"
              register={register}
              name="name"
              errors={formState.errors}
            />
            <Input
              title="Email"
              placeholder="yourmail@mail.com"
              register={register}
              name="email"
              errors={formState.errors}
            />
            <div className="flex flex-row justify-between items-center">
              <InputPassword
                title="Create Password"
                placeholder="Password"
                name="password"
                register={register}
                width="48%"
                errors={formState.errors}
              />
              <InputPassword
                title="Confirm Password"
                placeholder="Password"
                name="confirm_password"
                register={register}
                width="48%"
                errors={formState.errors}
              />
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="w-[48%]">
                <InputDate
                  title="Birth Date"
                  control={control}
                  name="date_of_birth"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={90}
                  maxDate={CURRENT_TIME}
                  errors={formState.errors}
                />
              </div>
              <Input
                title="Referral code (optional)"
                placeholder="Code"
                name="referral_code"
                register={register}
                errors={formState.errors}
                classes="w-[48%]"
                isDisabled={referrerId}
              />
            </div>
            {referrerId ? (
              <div className="flex flex-col text-sm mb-[16px]">
                Referral code
                <div className="flex flex-row items-center gap-[4px] mt-[4px]">
                  <ion-icon
                    name="checkmark-circle"
                    style={{ width: '16px', height: '16px', color: '#5ABA47' }}
                  ></ion-icon>
                  <p>
                    you are using code from{' '}
                    <span className="opacity-50">{referrerId}</span>
                  </p>
                </div>
              </div>
            ) : null}
            <div className="flex flex-row items-center mb-[16px]">
              <button
                type="button"
                className="flex cursor-pointer"
                onClick={() => setAgreedTNC(!agreedTNC)}
              >
                <ion-icon
                  name={agreedTNC ? 'checkbox' : 'square-outline'}
                  style={{
                    width: '24px',
                    height: '24px',
                    color: '#3E3E3E',
                  }}
                ></ion-icon>
              </button>
              <p className="text-lg ml-[8px]">
                I accept the{' '}
                <span
                  className="underline cursor-pointer"
                  onClick={() =>
                    navigate(
                      `${encodeURI('/about?content_section=Service & Terms')}`
                    )
                  }
                >
                  terms & conditions
                </span>
              </p>
            </div>
            <ActionButton title="Register" type="submit" />
          </form>
        </div>
      </div>
    </LayoutUser>
  );
};

export default RegisterPage;
