import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { useDispatch, useSelector } from 'react-redux';
import { setBetAmount, setModalSection } from '../../slices/tradeSlice';
import Modal from '../Modal/Modal';
import SecondaryButton from '../Button/SecondaryButton';
import { enqueueSnackbar } from 'notistack';
import { tradeOption } from '../../actions/trade';
import getToastMessage from '../../utils/toastMessage';
import { useEffect, useState } from 'react';
import {
  calculateTimeDifference,
  formatTimer,
  reduceSecondsToDate,
} from '../../utils/date';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { thousandSeparator } from '../../utils/math';

const TradeOptions = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const {
    modalSection,
    selectedGroup,
    selectedCoin,
    betAmount,
    marketInfo,
    marketRatio,
    myTrade,
    isLoading,
  } = useSelector((state) => state.trade);
  const [countdown, setCountdown] = useState('00:00');
  const [freezeCountdown, setFreezeCountdown] = useState('00:00');
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const handleBetAmountChanges = (ev) => {
    let inputString = ev.target.value,
      result = inputString.replace(/[^\d]/g, '');
    if (result && +result > selectedGroup.max_trade) {
      result = selectedGroup.max_trade;
    }
    dispatch(setBetAmount(result ? +result : ''));
  };

  const updateBetAmount = (type) => {
    if (type === 'in')
      dispatch(
        setBetAmount(
          betAmount + 5 > selectedGroup.max_trade
            ? selectedGroup.max_trade
            : betAmount + 5
        )
      );
    else if (type === 'de')
      dispatch(
        setBetAmount(
          betAmount - 5 < selectedGroup.min_trade
            ? selectedGroup.min_trade
            : betAmount - 5
        )
      );
  };

  const trade = async (type) => {
    if (freezeCountdown === '00:00') {
      if (
        (width < 1024 && modalSection !== 'INTERVAL_INFO') ||
        (width >= 1024 && modalSection !== 'INTERVAL_INFO_SM')
      )
        return dispatch(
          setModalSection(width < 1024 ? 'INTERVAL_INFO' : 'INTERVAL_INFO_SM')
        );
      return;
    } else if (betAmount > userInfo.lucky_coin_balance) {
      return enqueueSnackbar(`You don't have enough CKU`, {
        variant: 'warning',
      });
    } else if (
      betAmount < selectedGroup.min_trade ||
      betAmount > selectedGroup.max_trade
    ) {
      return enqueueSnackbar(
        `Your trade amount is ${
          betAmount < selectedGroup.min_trade ? 'less' : 'more'
        } than group's ${
          betAmount < selectedGroup.min_trade ? 'minimum' : 'maximum'
        } trade`,
        { variant: 'warning', autoHideDuration: 3000 }
      );
    }

    if (!isLoading) {
      const done = await dispatch(
        tradeOption({
          trade_amount: +betAmount,
          trade_type: type,
          binary_option_id: selectedCoin._id,
          trade_group_id: selectedGroup._id,
        })
      );

      if (done) {
        const { message, variant } = getToastMessage(done);
        if (variant === 'success') enqueueSnackbar(message, { variant });
      }
    }
  };

  useEffect(() => {
    if (marketInfo) {
      const freezeTimeDate = reduceSecondsToDate(
        selectedCoin.latest_trade_time.value,
        selectedCoin.latest_trade_time.unit,
        marketInfo.endDate
      );

      if (calculateTimeDifference(freezeTimeDate)) {
        let timer = calculateTimeDifference(freezeTimeDate, true);
        setFreezeCountdown(timer);
      }

      if (calculateTimeDifference(marketInfo.endDate)) {
        let timer = calculateTimeDifference(marketInfo.endDate, true);
        setCountdown(timer);
      }

      var freezeInterval = setInterval(() => {
        if (calculateTimeDifference(freezeTimeDate)) {
          let timer = calculateTimeDifference(freezeTimeDate, true);
          setFreezeCountdown(timer);
          if (timer === '00:00') {
            clearInterval(freezeInterval);
            enqueueSnackbar('No more trades', { variant: 'info' });
          }
        }
      }, 1000);

      var interval = setInterval(() => {
        if (calculateTimeDifference(marketInfo.endDate)) {
          let timer = calculateTimeDifference(marketInfo.endDate, true);
          setCountdown(timer);
          if (timer === '00:00') clearInterval(interval);
        }
      }, 1000);

      return () => {
        clearInterval(freezeInterval);
        clearInterval(interval);
      };
    } else {
      setFreezeCountdown('00:00');
      setCountdown('00:00');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketInfo]);

  const TradeButton = ({ market }) => {
    return (
      <button
        type="button"
        className={`cursor-pointer w-[49%] sm:w-full text-white-f flex flex-row justify-between items-start p-[12px] rounded-lg ${
          market === 'Bull' ? 'bg-light-green' : 'bg-red'
        } ${freezeCountdown === '00:00' ? 'opacity-50' : 'opacity-100'}`}
        onClick={() => trade(market)}
      >
        <span className="flex flex-col">
          <p className="font-poppins-semibold">{market} Market</p>
          <span className="flex flex-row items-center gap-[5px]">
            <ion-icon
              name="people"
              style={{ width: '11px', height: '11px', color: '#fff' }}
            ></ion-icon>
            <p className="text-sm">
              1 : {market === 'Bull' ? marketRatio.bull : marketRatio.bear}
            </p>
          </span>
          {myTrade ? (
            <p className="text-sm">
              My Trade:{' '}
              {thousandSeparator(
                market === 'Bull' ? myTrade.bull : myTrade.bear
              )}{' '}
              CKU
            </p>
          ) : null}
        </span>
        {market === 'Bull' ? (
          <NorthIcon style={{ color: '#fff' }} />
        ) : (
          <SouthIcon style={{ color: '#fff' }} />
        )}
      </button>
    );
  };

  const IntervalTimer = () => {
    return (
      <div className="relative max-w-[30%] min-w-[30%] z-10 sm:hidden sm:invisible flex flex-col items-center justify-center bg-grey-10 py-[12px] px-[6px] rounded-[10px]">
        <ion-icon
          name="time-outline"
          style={{ width: '20px', height: '20px' }}
        ></ion-icon>
        <span className="flex flex-row mt-[2px]">
          <p className="text-sm">{countdown}</p>
          <button
            className={`${freezeCountdown === '00:00' ? 'flex' : 'hidden'}`}
            onClick={() => dispatch(setModalSection('INTERVAL_INFO'))}
          >
            <ion-icon
              name="help-circle-outline"
              style={{ width: '16px', height: '16px', color: '#70808C' }}
            ></ion-icon>
          </button>
        </span>
        {freezeCountdown !== '00:00' ? (
          <p className="text-[10px] text-[#70808C]">
            {`(closes at ${formatTimer(selectedCoin.latest_trade_time)})`}
          </p>
        ) : null}
      </div>
    );
  };

  const IntervalTimerSM = () => {
    return (
      <div className="hidden relative w-full sm:flex flex-col bg-white-btn rounded-lg px-[16px] py-[12px]">
        <div className="flex flex-row justify-between items-center">
          <span className="flex flex-row items-center gap-[6px] mb-[4px]">
            <ion-icon
              name="time-outline"
              style={{ width: '20px', height: '20px' }}
            ></ion-icon>
            <span className="flex flex-col">
              <p className="text-[11px] text-[#70808C]">
                {freezeCountdown === '00:00' ? 'Freeze Time' : `Trade Open`}
              </p>
              {freezeCountdown !== '00:00' ? (
                <p className="text-[10px] text-[#70808C]">
                  {`(closes at ${formatTimer(selectedCoin.latest_trade_time)})`}
                </p>
              ) : null}
            </span>
          </span>
          {freezeCountdown === '00:00' ? (
            <button
              className="flex"
              onClick={() => dispatch(setModalSection('INTERVAL_INFO_SM'))}
            >
              <ion-icon
                name="help-circle-outline"
                style={{ width: '16px', height: '16px', color: '#70808C' }}
              ></ion-icon>
            </button>
          ) : null}
        </div>
        <p className="text-sm">{countdown}</p>
        {modalSection === 'INTERVAL_INFO_SM' ? <IntervalInfo /> : null}
      </div>
    );
  };

  const IntervalInfo = () => {
    return (
      <div className="sm:absolute sm:left-[-276px] w-full sm:w-[266px] sm:top-[-50%] bg-[rgba(255,255,255,0.10)] backdrop-blur-md flex flex-col sm:p-[12px] text-sm rounded-lg sm:border sm:border-[#DADADA] gap-[10px]">
        <div className="flex flex-row justify-between items-center">
          <span className="flex flex-row items-center gap-[6px] mb-[4px]">
            <ion-icon
              name="time-outline"
              style={{ width: '20px', height: '20px' }}
            ></ion-icon>
            <p className="text-[11px] text-[#70808C]">Freeze Time</p>
          </span>
          <button
            className="sm:flex hidden"
            onClick={() => dispatch(setModalSection(''))}
          >
            <ion-icon
              name="remove-circle-outline"
              style={{ width: '16px', height: '16px', color: '#70808C' }}
            ></ion-icon>
          </button>
        </div>
        <p>
          Kindly wait for a moment as there is currently a purchase freeze in
          place before the price updates.
        </p>
      </div>
    );
  };

  return (
    <>
      {modalSection === 'INTERVAL_INFO' ? (
        <Modal
          closeModal={setModalSection}
          closeWithDispatch={true}
          hideCloseBtn={true}
        >
          <IntervalInfo />
          <SecondaryButton
            onClick={() => dispatch(setModalSection(''))}
            title="Close"
            classes="!py-[10px] mt-[10px] !w-full !rounded-lg !text-sm !bg-transparent border border-black-100"
          />
        </Modal>
      ) : null}
      <div className="flex flex-col sm:flex-col-reverse px-[12px] sm:px-0 pb-[12px] sm:pb-0 w-full gap-[8px]">
        <div className="flex flex-row sm:flex-col-reverse items-center sm:gap-[12px] justify-between">
          <TradeButton market="Bear" />
          {/* <IntervalTimer /> */}
          <TradeButton market="Bull" />
        </div>
        <IntervalTimerSM />
        <section className="flex sm:flex-col justify-between flex-row">
          <IntervalTimer />
          <div className="flex flex-col w-[68%] sm:w-full">
            <div className="bg-white-btn py-[12px] px-[24px] rounded-lg flex flex-row justify-between items-center">
              <button
                className="flex sm:hidden"
                type="button"
                onClick={() => updateBetAmount('de')}
              >
                <RemoveIcon style={{ color: '#007AFF', fontSize: '24px' }} />
              </button>
              <span className="flex flex-col items-center w-[40%] sm:w-full">
                <label className="text-[10px] text-center text-[#70808C]">
                  Trade Amount, CKU
                </label>
                <input
                  type="number"
                  value={betAmount}
                  onChange={handleBetAmountChanges}
                  className="text-lg text-center w-full border- outline-0 bg-transparent"
                />
              </span>
              <button
                className="flex sm:hidden"
                type="button"
                onClick={() => updateBetAmount('in')}
              >
                <AddIcon style={{ color: '#007AFF', fontSize: '24px' }} />
              </button>
            </div>
            {userInfo && betAmount > userInfo.lucky_coin_balance ? (
              <p className="text-[10px] text-red text-center">
                You don't have enough CKU
              </p>
            ) : null}
          </div>
          <div className="hidden sm:flex flex-row mt-[4px] justify-between w-full">
            <button
              className="flex flex-col justify-center rounded-lg py-[6px] items-center w-[48%] bg-white-btn"
              type="button"
              onClick={() => updateBetAmount('de')}
            >
              <RemoveIcon style={{ color: '#007AFF', fontSize: '24px' }} />
            </button>
            <button
              className="flex flex-col justify-center rounded-lg py-[6px] items-center w-[48%] bg-white-btn"
              type="button"
              onClick={() => updateBetAmount('in')}
            >
              <AddIcon style={{ color: '#007AFF', fontSize: '24px' }} />
            </button>
          </div>
        </section>
      </div>
    </>
  );
};

export default TradeOptions;
