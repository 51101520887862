import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataLimit from '../Table/DataLimit';
import { setTradePageData } from '../../slices/playerSlice';
import StyledTableContainer from '../Table/StyledTableContainer';
import StyledTable from '../Table/StyledTable';
import { TableBody, TableHead, TableRow } from '@mui/material';
import StyledTableCell from '../Table/StyledTableCell';
import { fetchPlayerTradeHistory } from '../../actions/player';
import InputSearch from '../Input/InputSearch';
import Pagination from '../Table/Pagination';
import moment from 'moment-timezone';
import { thousandSeparator } from '../../utils/math';
import { currentTimezone } from '../../utils/date';

const TicketHistory = () => {
  const { tradePageData, tradeTotalPages, tradeHistory } = useSelector(
    (state) => state.player
  );
  const dispatch = useDispatch();

  const onSearch = (ev) => {
    if (ev.key === 'Enter' && ev.target.value !== tradePageData.search) {
      const search = ev.target.value;
      dispatch(setTradePageData({ search }));
    }
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== tradePageData.page) {
      dispatch(setTradePageData({ page: ev.selected }));
    }
  };

  useEffect(() => {
    dispatch(fetchPlayerTradeHistory(tradePageData));
  }, [dispatch, tradePageData]);

  return (
    <>
      <p className="text-blue font-poppins-semibold mb-[16px]">
        Trading History
      </p>
      <InputSearch
        title="Search"
        onSearch={onSearch}
        placeholder="Currency, ID"
      />
      <DataLimit
        initialLimit={tradePageData.dataLimit}
        setter={setTradePageData}
        classList="mb-[16px]"
      />
      {tradeHistory.length ? (
        <div>
          <StyledTableContainer>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Currency/USD</StyledTableCell>
                  <StyledTableCell>Transaction ID</StyledTableCell>
                  <StyledTableCell>Trade Time Start</StyledTableCell>
                  <StyledTableCell>Trade Time End</StyledTableCell>
                  <StyledTableCell>Trade Group</StyledTableCell>
                  <StyledTableCell>Market</StyledTableCell>
                  <StyledTableCell>Trade Result</StyledTableCell>
                  <StyledTableCell>CKU Amount</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tradeHistory.map((trade) => (
                  <TableRow key={trade._id}>
                    <StyledTableCell>
                      {trade.coin_info.currency}
                    </StyledTableCell>
                    <StyledTableCell>{trade.transaction_id}</StyledTableCell>
                    <StyledTableCell>
                      {moment(trade.createdAt)
                        .tz(currentTimezone())
                        .format('DD-MM-YYYY, HH:mm')}
                    </StyledTableCell>
                    <StyledTableCell>
                      {trade.market_info && trade.market_info.endDate
                        ? moment(trade.market_info.endDate)
                            .tz(currentTimezone())
                            .format('DD-MM-YYYY, HH:mm')
                        : 'TBD'}
                    </StyledTableCell>
                    <StyledTableCell>{trade.trade_group.name}</StyledTableCell>
                    <StyledTableCell>{trade.trade_type}</StyledTableCell>
                    <StyledTableCell>
                      {Math.sign(trade.trade_result) !== -1 &&
                      trade.trade_result
                        ? '+'
                        : ''}
                      {trade.trade_result ? trade.trade_result : 'TBD'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {thousandSeparator(trade.trade_amount)}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
          <Pagination
            totalPages={tradeTotalPages}
            pageData={tradePageData}
            onChange={handlePageChange}
          />
        </div>
      ) : (
        <p>Data not found!</p>
      )}
    </>
  );
};

export default TicketHistory;
