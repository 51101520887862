import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../utils/API';
import { enqueueSnackbar } from 'notistack';
import { setPlayerBalance } from '../slices/authSlice';
import { setRedirectedToTrade } from '../slices/commonSlice';

export const fetchBinaryOptionList = createAsyncThunk(
  'trade/fetchBinaryOptionList',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await serverAPI.get('getBinaryOptionList', {
        params,
      });
      dispatch(setRedirectedToTrade(true));
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchBinaryOptionDetail = createAsyncThunk(
  'trade/fetchBinaryOptionDetail',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('getBinaryOptionDetail', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const tradeOption = createAsyncThunk(
  'trade/tradeOption',
  async (tradeData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await serverAPI.post('createTrade', tradeData);
      if (data && data.new_trader_balance !== undefined) {
        dispatch(setPlayerBalance(data.new_trader_balance));
      }
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
