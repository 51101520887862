import { TableCell, tableCellClasses, styled } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#ffffff',
    color: '#212121',
    fontFamily: 'PoppinsSemiBold',
    lineHeight: '14px',
    padding: '8px',
    fontSize: 12,
    border: '0px',
    borderTop: '1px solid #e5e5e5',
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#ffffff',
    color: '#212121',
    fontFamily: 'Poppins',
    padding: '8px',
    fontSize: 12,
    border: '0px',
    borderTop: '1px solid #e5e5e5',
  },
}));

export default StyledTableCell;
