export const thousandSeparator = (value, decimals = 2) => {
  if (!value) return 0;
  value = value % 1 !== 0 ? truncateDecimals(value, decimals) : value;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const truncateDecimals = (number, decimalPlaces) => {
  // Multiply by 10^decimalPlaces to shift the decimal places to the right
  // Use Math.floor() to truncate the decimal part
  // Divide by 10^decimalPlaces to shift the decimal places back to the original position
  return (
    Math.floor(number * Math.pow(10, decimalPlaces)) /
    Math.pow(10, decimalPlaces)
  );
};

export const calculateRatio = (a, b, p) => {
  return truncateDecimals(a / (b / (p / 100)) + 1, 3);
};

export const formatNumberWithSuffix = (number) => {
  if (number >= 1000000) {
    return +(number / 1000000).toFixed(1).toString() + 'm';
  } else if (number >= 1000) {
    return +(number / 1000).toFixed(1).toString() + 'K';
  } else {
    return number.toString();
  }
};

export const generateNumberRange = (start, finish) => {
  return Array.from(
    { length: finish - start + 1 },
    (_, index) => start + index
  );
};

export const getCombinationsCount = (arr) => {
  // Helper function to generate combinations
  function generateCombinations(
    arr,
    start,
    count,
    currentCombination,
    combinations
  ) {
    if (count === 0) {
      combinations.push(currentCombination.slice());
      return;
    }
    for (let i = start; i < arr.length; i++) {
      currentCombination.push(arr[i]);
      generateCombinations(
        arr,
        i + 1,
        count - 1,
        currentCombination,
        combinations
      );
      currentCombination.pop();
    }
  }

  if (arr.length < 6) {
    // If the array has fewer than 6 numbers, there are no combinations.
    return 0;
  }

  const combinations = [];
  generateCombinations(arr, 0, 6, [], combinations);
  return combinations.length;
};

export const getTicketCombinations = (arr) => {
  // Helper function to generate combinations
  function createCombination(
    arr,
    start,
    count,
    currentCombination,
    combinations
  ) {
    if (count === 0) {
      combinations.push(currentCombination.slice());
      return;
    }
    for (let i = start; i < arr.length; i++) {
      currentCombination.push(arr[i]);
      createCombination(
        arr,
        i + 1,
        count - 1,
        currentCombination,
        combinations
      );
      currentCombination.pop();
    }
  }

  if (arr.length < 6) {
    // If the array has fewer than 6 numbers, there are no combinations.
    return [];
  }

  arr = JSON.parse(JSON.stringify(arr)).sort((a, b) => a - b);
  const combinations = [];
  createCombination(arr, 0, 6, [], combinations);
  console.log(combinations, '====combinations');
  return combinations;
};

export const removePurchasedTickets = (
  combinations,
  purchased,
  selectedIds
) => {
  if (combinations.length && purchased.length && selectedIds.length) {
    // console.log(selectedIds, '=====ids');
    const cart = selectedIds.flatMap((id) =>
      combinations.map((numbers) => ({
        drawdown_id: id,
        ticket_number: numbers,
      }))
    );

    purchased = purchased.map((ticket) => {
      return {
        drawdown_id: ticket.drawdown.id,
        ticket_number: ticket.ticket_number,
      };
    });

    console.log(cart, '====CART');
    console.log(purchased, '====PURCHASED');

    const notPurchased = cart.filter((cartItem) => {
      // Check if there is no matching element in the purchased array
      return !purchased.some(
        (purchasedItem) =>
          purchasedItem.drawdown_id === cartItem.drawdown_id &&
          purchasedItem.ticket_number.every((ticketNumber) =>
            cartItem.ticket_number.includes(ticketNumber)
          )
      );
    });

    console.log(notPurchased, '====NOT PURCHASED');
    return notPurchased.length;
  }
};

export const getTotalTickets = (ticketList) => {
  return ticketList.length
    ? ticketList
        .map((t) => t.ticket_list.length)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    : 0;
};
