import axios from 'axios';

const baseURL =
  process.env.REACT_APP_API === 'production'
    ? 'https://duotrx.com/api/'
    : 'https://staging.duotrx.com/api/';

const serverAPI = axios.create({
  baseURL,
});

serverAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('USER_KEY');
    config.metadata = { startTime: new Date() };
    if (token) config.headers.Authorization = `Bearer ${token}`;
    config.timeout = 120000;
    return config;
  },
  (error) => Promise.reject(error)
);

serverAPI.interceptors.response.use(
  (response) => {
    response.config.metadata.endTime = new Date();
    response.duration =
      (response.config.metadata.endTime - response.config.metadata.startTime) /
      1000;
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('USER_KEY');
      window.location.assign('/login');
      setTimeout(() => {
        window.location.assign(window.location);
      }, 1000);
    }
    return Promise.reject(error.response.data);
  }
);

const cancelToken = axios.CancelToken;

export { serverAPI, cancelToken, baseURL };
