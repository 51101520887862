import { useEffect, useState } from 'react';
import { useIsLoggedIn } from '../../hooks/useAuth';
import Logo from '../../assets/imgs/duotrx-logo.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContactPlatform } from '../../actions/common';

const Footer = () => {
  const isLoggedIn = useIsLoggedIn();
  const { contactPlatformList, fetchedContact } = useSelector(
    (state) => state.common
  );
  const dispatch = useDispatch();
  const [links, setLinks] = useState([
    { name: 'Log In', path: '/login' },
    { name: 'Sign Up', path: '/signup' },
    { name: 'Contact', path: '/contact' },
    { name: 'FAQ', path: '/faq' },
    { name: 'Referral Program', path: '/referral' },
  ]);
  const [openAccount, setOpenAccount] = useState(true);
  const [openLegal, setOpenLegal] = useState(true);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const legalContent = [
    'About Us',
    'Privacy Policy',
    'Responsible Trading',
    'Service & Terms',
  ];

  useEffect(() => {
    if (isLoggedIn) {
      const loggedInLinks = links.filter(
        (link) => !link.name.includes('Sign') && !link.name.includes('Log')
      );
      setLinks(loggedInLinks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (!fetchedContact) {
      dispatch(fetchContactPlatform());
    }
  }, [dispatch, fetchedContact]);

  return (
    <section className="w-full py-[60px] px-[16px] sm:px-[5%] bg-[#FAFAFF]">
      <div className="flex flex-col gap-[16px] sm:flex-row sm:gap-[10%]">
        <div className="flex flex-col">
          <button
            onClick={() => {
              if (width < 1024) setOpenAccount(!openAccount);
            }}
            className="relative z-10 pb-[18px] flex flex-row items-center justify-between border-b border-b-[#404059] text-[18px]"
          >
            Menu
            {width < 1024 ? (
              <ion-icon
                name={`chevron-${openAccount ? 'up' : 'down'}-outline`}
                style={{ fontSize: '24px' }}
              ></ion-icon>
            ) : null}
          </button>
          <div
            className={`flex flex-col mt-[10px] transition-all duration-300 ${
              openAccount
                ? 'max-h-[50vh] overflow-auto h-full opacity-100'
                : 'max-h-0 opacity-0 h-0'
            }`}
          >
            {links.map((link) => (
              <span
                key={link.name}
                className={`leading-8 text-sm text-[#404059] cursor-pointer`}
                onClick={() => {
                  if (link.path) {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    navigate(link.path);
                  }
                }}
              >
                {link.name}
              </span>
            ))}
          </div>
        </div>
        <div className="flex flex-col">
          <button
            onClick={() => {
              if (width < 1024) setOpenLegal(!openLegal);
            }}
            className="relative z-10 pb-[18px] flex flex-row items-center justify-between border-b border-b-[#404059] text-[18px]"
          >
            Other Pages
            {width < 1024 ? (
              <ion-icon
                name={`chevron-${openLegal ? 'up' : 'down'}-outline`}
                style={{ fontSize: '24px' }}
              ></ion-icon>
            ) : null}
          </button>
          <div
            className={`flex flex-col mt-[10px] transition-all duration-300 ${
              openLegal
                ? 'max-h-[50vh] overflow-auto h-full opacity-100'
                : 'max-h-0 opacity-0 h-0'
            }`}
          >
            {legalContent.map((content, idx) => (
              <span
                key={`${content}-${idx}`}
                className={`text-sm text-[#404059] leading-8 cursor-pointer`}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  navigate(encodeURI(`/about?content_section=${content}`));
                }}
              >
                {content}
              </span>
            ))}
          </div>
        </div>
      </div>
      <p className="text-sm mt-[20px] sm:w-[70%]">
        <span className="font-poppins-semibold">*Risk warning:</span> CKU is only a gaming token for participants to use on the Duotrx.com platform and can not be exchanged/returned/sold to Duotrx and any of its affiliated
companies for any financial rebates/returns/gains. Participants are purchasing the CKU for the sole purpose of playing Duotrx.com.. The only financial reward being offered
to participants are the prize offered to winners of tournament, and nothing more.
      </p>
      {contactPlatformList && contactPlatformList.length ? (
        <div className="flex flex-row gap-[20px] mt-[20px] items-center">
          {contactPlatformList.map((contact) => (
            <button
              className="flex h-[32px] w-[32px]"
              type="button"
              key={contact._id}
              onClick={() =>
                window.open(contact.link, '_blank', 'noopener,noreferrer')
              }
            >
              <img
                src={contact.icon}
                alt={contact.name}
                className="w-full h-full object-contain"
              />
            </button>
          ))}
        </div>
      ) : null}
      <article className="py-[15px] mt-[60px] sm:flex sm:flex-row sm:items-center sm:gap-[2%]">
        <img src={Logo} alt="duotrx-logo" className="w-[105px] h-[25px]" />
        <p className="text-sm">Copyright © 2024 Brand All Rights Reserved</p>
      </article>
    </section>
  );
};

export default Footer;
