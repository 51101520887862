import { createSlice } from '@reduxjs/toolkit';
import {
  addReferrer,
  checkReferrer,
  fetchPlayerInfo,
  forgotPasswordStep1,
  forgotPasswordStep2,
  forgotPasswordStep3,
  playerLogin,
  registerPlayerResendOTP,
  registerPlayerStep1,
  registerPlayerStep2,
  sendEmailMessage,
} from '../actions/auth';
import { truncateDecimals } from '../utils/math';

const initialState = {
  userInfo: null,
  isLoading: false,
  forgotPassPayload: null,
  referrerId: '',
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setPlayerBalance: (state, action) => {
      const user = state.userInfo;
      action.payload && action.payload % 1 !== 0
        ? (user.lucky_coin_balance = truncateDecimals(action.payload, 2))
        : (user.lucky_coin_balance = action.payload);
      state.userInfo = user;
    },
    setForgotPassPayload: (state, action) => {
      state.forgotPassPayload = action.payload;
    },
  },
  extraReducers: {
    [playerLogin.pending]: (state) => {
      state.isLoading = true;
    },
    [playerLogin.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [playerLogin.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [fetchPlayerInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPlayerInfo.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      const playerData = payload.trader_data;
      if (
        playerData.lucky_coin_balance &&
        playerData.lucky_coin_balance % 1 !== 0
      ) {
        playerData.lucky_coin_balance = truncateDecimals(
          playerData.lucky_coin_balance,
          2
        );
      }
      state.userInfo = playerData;
    },
    [fetchPlayerInfo.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [registerPlayerStep1.pending]: (state) => {
      state.isLoading = true;
    },
    [registerPlayerStep1.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [registerPlayerStep1.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [registerPlayerStep2.pending]: (state) => {
      state.isLoading = true;
    },
    [registerPlayerStep2.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [registerPlayerStep2.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [registerPlayerResendOTP.pending]: (state) => {
      state.isLoading = true;
    },
    [registerPlayerResendOTP.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [registerPlayerResendOTP.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [forgotPasswordStep1.pending]: (state) => {
      state.isLoading = true;
    },
    [forgotPasswordStep1.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [forgotPasswordStep1.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [forgotPasswordStep2.pending]: (state) => {
      state.isLoading = true;
    },
    [forgotPasswordStep2.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [forgotPasswordStep2.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [forgotPasswordStep3.pending]: (state) => {
      state.isLoading = true;
    },
    [forgotPasswordStep3.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload.user) {
        state.userInfo = payload.user;
        window.location.replace(`/`);
      }
    },
    [forgotPasswordStep3.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [addReferrer.pending]: (state) => {
      state.isLoading = true;
    },
    [addReferrer.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload.player_data) state.userInfo = payload.player_data;
    },
    [addReferrer.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [checkReferrer.pending]: (state) => {
      state.isLoading = true;
    },
    [checkReferrer.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (payload.referrer_id) state.referrerId = payload.referrer_id;
    },
    [checkReferrer.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
    [sendEmailMessage.pending]: (state) => {
      state.isLoading = true;
    },
    [sendEmailMessage.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [sendEmailMessage.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserInfo, setForgotPassPayload, setPlayerBalance } =
  auth.actions;

export default auth.reducer;
