import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverAPI } from '../utils/API';
import { enqueueSnackbar } from 'notistack';

export const fetchPlayerTransactionHistory = createAsyncThunk(
  'player/playerTransactionHistory',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('traderTransactionHistory', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchPlayerTradeHistory = createAsyncThunk(
  'player/playerTicketHistory',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('traderTradeHistory', { params });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchPlayerTransactionDetail = createAsyncThunk(
  'player/playerTransactionDetail',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('playerTransactionDetail', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchPlayerFriendsList = createAsyncThunk(
  'player/fetchPlayerFriendsList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await serverAPI.get('getReferralList', {
        params,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        return rejectWithValue(error.response.data.message);
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
        return rejectWithValue(error.message);
      }
    }
  }
);
