import { useIsLoggedIn } from '../hooks/useAuth';
import LayoutUser from '../components/Layout/LayoutUser';
import ActionButton from '../components/Button/ActionButton';
import ReferralImg from '../assets/imgs/referral.jpeg';
import ReferralIcon1 from '../assets/icons/referral-icon-1.png';
import ReferralIcon2 from '../assets/icons/referral-icon-2.png';
import ReferralIcon3 from '../assets/icons/referral-icon-3.png';
import ReferralBg from '../assets/imgs/referral-bg.jpeg';
import { useNavigate } from 'react-router-dom';
import 'aos/dist/aos.css';

const ReferralPage = () => {
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();

  const ReferralCard = ({ icon, iconAlt, title, message, isLast }) => {
    return (
      <div
        data-aos="fade-down"
        data-aos-duration="800"
        className={`flex flex-row items-center sm:min-w-[33%] sm:max-w-[33%] gap-[16px] m-auto py-[15px] sm:py-0 sm:px-[42px] border-b sm:border-b-0 border-b-black-100 sm:border-r sm:border-r-black-100 ${
          isLast && 'sm:!border-r-0'
        }`}
      >
        <figure className="w-[70px] h-[70px]">
          <img
            src={icon}
            alt={iconAlt}
            className="w-full h-full object-contain"
          />
        </figure>
        <span className="flex flex-col w-[calc(100%-86px)] font-poppins-semibold">
          <p className="text-blue text-[22px]">{title}</p>
          <p className="text-lg">{message}</p>
        </span>
      </div>
    );
  };

  return (
    <LayoutUser>
      <section className="flex flex-col sm:flex-col-reverse py-[60px] px-[20px]">
        <figure
          data-aos="fade-left"
          data-aos-duration="800"
          className="w-full h-[40vh] sm:w-[90%] sm:h-[60vh] sm:m-auto rounded-[32px]"
        >
          <img
            src={ReferralImg}
            alt="referral-img"
            className="w-full h-full object-cover rounded-[32px]"
          />
        </figure>
        <article
          data-aos="fade-right"
          data-aos-duration="800"
          className="my-[24px] text-center sm:w-[40%] sm:mx-auto"
        >
          <h2 className="text-[30px] sm:text-[48px] font-poppins-bold mb-[15px] sm:mb-[30px]">
            Unlock Rewards{' '}
            <span className="text-blue">with Every Referral!</span>
          </h2>
          <p className="text-lg sm:text-[26px] text-[#404059] mb-[24px] sm:mb-[42px]">
            Join our Affiliate Program and start earning exciting rewards today!
          </p>
          <ActionButton
            title={isLoggedIn ? 'Share Now' : 'Register Now'}
            onClick={() =>
              navigate(isLoggedIn ? '/profile?section=referral' : '/login')
            }
            classes="sm:!w-[50%] sm:m-auto"
          />
        </article>
      </section>
      <section className="flex flex-col sm:flex-row sm:w-[90%] sm:px-0 sm:m-auto sm:justify-between sm:items-center py-[60px] px-[20px]">
        <h3
          data-aos="fade-up"
          className="font-poppins leading-tight text-center sm:text-left sm:text-[32px] w-full sm:w-[18%]"
        >
          How it works
        </h3>
        <div className="flex flex-col sm:flex-row mt-[12px] sm:w-[82%]">
          <ReferralCard
            icon={ReferralIcon1}
            alt="referral-icon-1"
            title={isLoggedIn ? 'Profile' : 'Register'}
            message={
              isLoggedIn
                ? 'Navigate to your profile, locate your Referral Link there.'
                : 'Join now and go to your profile to find your referral link.'
            }
          />
          <span className="w-[1px] h-["></span>
          <ReferralCard
            icon={ReferralIcon2}
            alt="referral-icon-2"
            title="Share"
            message="Share your unique referral link with friends and family."
          />
          <ReferralCard
            icon={ReferralIcon3}
            alt="referral-icon-3"
            title="Reward"
            isLast={true}
            message="Get a commission from your referral on every executed trade
                they make."
          />
        </div>
      </section>
      <section className="relative flex flex-col px-[20px] py-[25%] sm:py-[5%]">
        <img
          src={ReferralBg}
          alt="referral-bg"
          className="w-full h-full z-[-1] object-cover absolute top-0 left-0"
        />
        <article
          className="mb-[36px] text-center sm:w-[50%] sm:mx-auto"
          data-aos="fade-down"
          data-aos-duration="800"
        >
          <h3 className="text-[36px] text-blue">Unlock Limitless Earnings:</h3>
          <h3 className="text-[36px] text-white">Share More, Earn More!</h3>
          <p className="mt-[24px] text-white text-[24px]">
            Unlimited Earning Potential: The more you share, the more you earn!
          </p>
        </article>
        <ActionButton
          title={isLoggedIn ? 'Share Now' : 'Register Now'}
          onClick={() =>
            navigate(isLoggedIn ? '/profile?section=referral' : '/login')
          }
          classes="sm:!w-[25%] sm:m-auto"
          data-aos="fade-up"
          data-aos-duration="800"
        />
      </section>
    </LayoutUser>
  );
};

export default ReferralPage;
