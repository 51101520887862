import { useState } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Modal from '../Modal/Modal';
import ActionButton from '../Button/ActionButton';
import SecondaryButton from '../Button/SecondaryButton';
import { useSelector } from 'react-redux';

const PromotionalBanner = () => {
  const { width } = useWindowDimensions();
  const { selectedBanner } = useSelector((state) => state.trade);
  const [showModal, setShowModal] = useState(false);
  const [showBanner, setShowBannner] = useState(true);

  if (selectedBanner) {
    if (width < 1024) {
      return (
        <>
          {showModal ? (
            <Modal closeModal={setShowModal} hideCloseBtn={true}>
              <div className="flex flex-col gap-[12px] text-sm">
                <p className="font-poppins-semibold">
                  {selectedBanner.button_name}
                </p>
                <figure className="w-full h-[15vh] rounded-lg">
                  <img
                    src={selectedBanner.image}
                    alt="coin-gecko-banner"
                    className="w-full h-full object-cover rounded-lg"
                  />
                </figure>
                <p className="text-[#788793] whitespace-pre-wrap">
                  {selectedBanner.message}
                </p>
                <div className="flex flex-row justify-between">
                  <ActionButton
                    title={selectedBanner.button_name}
                    classes="!py-[10px] !rounded-lg !w-[48%] !text-sm"
                    onClick={() =>
                      window.open(
                        selectedBanner.button_link,
                        '_blank',
                        'noreferrer'
                      )
                    }
                  />
                  <SecondaryButton
                    onClick={() => setShowModal(false)}
                    title="Close"
                    classes="!py-[10px] !rounded-lg !w-[48%] !text-sm !bg-transparent border border-black-100"
                  />
                </div>
              </div>
            </Modal>
          ) : null}
          <div
            className={`flex flex-row w-[calc(100%-28px)] h-[46px] gap-[4px] ${
              showBanner ? 'opacity-100' : 'opacity-0 invisible'
            }`}
          >
            <button
              type="button"
              className="w-[45%] h-full rounded-lg shadow-banner-button "
              onClick={() => setShowModal(true)}
            >
              <img
                src={selectedBanner.image}
                alt="banner-logo"
                className="w-full h-full rounded-lg object-cover"
              />
            </button>
            <button
              className="flex self-start w-[24px]"
              onClick={() => setShowBannner(false)}
            >
              <ion-icon
                name="close-circle-outline"
                style={{ width: '24px', height: '24px', color: '#FD5353' }}
              ></ion-icon>
            </button>
          </div>
        </>
      );
    } else if (width >= 1024 && showBanner) {
      return (
        <div className="fixed w-[60vw] max-h-[15vh] overflow-auto justify-between bottom-0 left-1/2 transform -translate-x-1/2 py-[12px] px-[24px] text-sm rounded-lg border border-[#D4D4D4] flex flex-row items-center gap-[12px]">
          <figure className="w-[40%] h-[10vh] rounded-lg">
            <img
              src={selectedBanner.image}
              alt="coin-gecko-banner"
              className="w-full h-full object-cover rounded-lg"
            />
          </figure>
          <p className="text-[#788793] max-w-[35%] whitespace-pre-wrap">
            {selectedBanner.message}
          </p>
          <ActionButton
            title={selectedBanner.button_name}
            classes="!w-fit !py-[10px] px-0 rounded-lg !text-sm h-fit"
            onClick={() =>
              window.open(selectedBanner.button_link, '_blank', 'noreferrer')
            }
          />
          <button
            className="flex self-start"
            onClick={() => setShowBannner(false)}
          >
            <ion-icon
              name="close-circle-outline"
              style={{ width: '24px', height: '24px', color: '#FD5353' }}
            ></ion-icon>
          </button>
        </div>
      );
    }
  }

  return null;
};

export default PromotionalBanner;
