import { useDispatch, useSelector } from 'react-redux';
import DataLimit from '../Table/DataLimit';
import StyledTable from '../Table/StyledTable';
import StyledTableContainer from '../Table/StyledTableContainer';
import StyledTableCell from '../Table/StyledTableCell';
import Pagination from '../Table/Pagination';
import { setTrsPageData } from '../../slices/playerSlice';
import { useEffect } from 'react';
import { fetchPlayerTransactionHistory } from '../../actions/player';
import { TableBody, TableHead, TableRow } from '@mui/material';
import InputSearch from '../Input/InputSearch';
import moment from 'moment-timezone';
import { thousandSeparator } from '../../utils/math';
import { currentTimezone } from '../../utils/date';

const TrsHistory = () => {
  const { trsPageData, trsTotalPages, transactionHistory } = useSelector(
    (state) => state.player
  );
  const dispatch = useDispatch();
  const onSearch = (ev) => {
    if (ev.key === 'Enter' && ev.target.value !== trsPageData.search) {
      const search = ev.target.value;
      dispatch(setTrsPageData({ search }));
    }
  };

  const handlePageChange = (ev) => {
    if (ev.selected !== trsPageData.page) {
      dispatch(setTrsPageData({ page: ev.selected }));
    }
  };

  useEffect(() => {
    dispatch(fetchPlayerTransactionHistory(trsPageData));
  }, [dispatch, trsPageData]);

  return (
    <>
      <p className="text-blue font-poppins-semibold mb-[16px]">
        Transaction History
      </p>
      <InputSearch
        title="Search"
        onSearch={onSearch}
        placeholder="Type, Sender/Receiver, Wallet Address"
      />
      <DataLimit
        initialLimit={trsPageData.dataLimit}
        setter={setTrsPageData}
        classList="mb-[16px]"
      />
      {transactionHistory.length ? (
        <div>
          <StyledTableContainer>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Transaction Date</StyledTableCell>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                  <StyledTableCell>Currency/USD</StyledTableCell>
                  <StyledTableCell>Market</StyledTableCell>
                  <StyledTableCell>Sender</StyledTableCell>
                  <StyledTableCell>Receiver</StyledTableCell>
                  <StyledTableCell>Notes</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionHistory.map((transaction) => (
                  <TableRow key={transaction._id}>
                    <StyledTableCell>
                      {moment(transaction.createdAt)
                        .tz(currentTimezone())
                        .format('DD-MM-YYYY, HH:mm')}
                    </StyledTableCell>
                    <StyledTableCell>
                      {transaction.transaction_type}
                    </StyledTableCell>
                    <StyledTableCell>
                      CKU {thousandSeparator(transaction.total_payment)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {transaction.market_info &&
                      transaction.market_info.currency
                        ? transaction.market_info.currency
                        : '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {transaction.market_info &&
                      transaction.market_info.trade_type
                        ? transaction.market_info.trade_type
                        : '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {transaction.sender_receiver.sender
                        ? transaction.sender_receiver.sender.wallet_address
                          ? transaction.sender_receiver.sender.wallet_address
                          : transaction.sender_receiver.sender.name
                        : '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {transaction.sender_receiver.receiver
                        ? transaction.sender_receiver.receiver.wallet_address
                          ? transaction.sender_receiver.receiver.wallet_address
                          : transaction.sender_receiver.receiver.name
                        : '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {transaction.notes ? transaction.notes : '-'}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
          <Pagination
            totalPages={trsTotalPages}
            pageData={trsPageData}
            onChange={handlePageChange}
          />
        </div>
      ) : (
        <p>Data not found!</p>
      )}
    </>
  );
};

export default TrsHistory;
