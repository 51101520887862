import { thousandSeparator } from '../../utils/math';
import CoinLogo from '../../assets/imgs/coin.png';
import { useSelector } from 'react-redux';

const LCAmount = ({ showNote = false, section }) => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  return (
    <div
      className={`p-[8px] border border-black-100 flex flex-col w-full my-[16px] bg-light-yellow ${
        section === 'Get LC' && '!p-0 !m-0 border-0'
      }`}
    >
      <h3 className="text-sm font-poppins-semibold opacity-50">CKU Balance</h3>
      <div className="flex flex-row items-center my-[2px]">
        <img
          src={CoinLogo}
          alt="coin logo"
          style={{ width: '28px', height: '28px' }}
        />
        <p className="text-sm ml-[4px] font-poppins-semibold">
          {thousandSeparator(
            userInfo && userInfo.lucky_coin_balance
              ? userInfo.lucky_coin_balance
              : 0
          )}
        </p>
      </div>
    </div>
  );
};

export default LCAmount;
