import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isMobileNavOpen: false,
  selectedMenu: 'Home',
  selectedTab: 'Home',
  tabMenus: [
    { name: 'Home', iconName: 'home', path: '/' },
    { name: 'Trading', iconName: 'bowling-ball', path: '/trade' },
    { name: 'History', iconName: 'ticket', path: '/history' },
  ],
  menus: [
    { name: 'Login', path: '/login' },
    {
      name: 'FAQ',
      path: '/faq',
    },
    {
      name: 'Contact',
      path: '/contact',
    },
    {
      name: 'Referral Program',
      path: '/referral',
    },
  ],
};

export const navbar = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setSelectedMenu: (state, action) => {
      state.selectedMenu = action.payload;
    },
    updateMenus: (state, action) => {
      state.menus = action.payload;
    },
    setSelectedTabMenu: (state, action) => {
      state.selectedTab = action.payload;
    },
    setIsMobileNavOpen: (state, action) => {
      state.isMobileNavOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedMenu,
  setIsMobileNavOpen,
  setSelectedTabMenu,
  updateMenus,
} = navbar.actions;

export default navbar.reducer;
