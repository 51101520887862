import { useEffect } from 'react';
import LayoutTrade from '../components/Layout/LayoutTrade';
import CurrencySelect from '../components/Trade/CurrencySelect';
import TradeGroup from '../components/Trade/TradeGroup';
import TradeOptions from '../components/Trade/TradeOptions';
import RoundPrize from '../components/Trade/RoundPrice';
import { useDispatch, useSelector } from 'react-redux';
import TradingViewWidget from '../components/Trade/TradingViewWidget';
import PromotionalBanner from '../components/Trade/PromotionalBanner';
import {
  fetchBinaryOptionDetail,
  fetchBinaryOptionList,
} from '../actions/trade';
import { useParams } from 'react-router-dom';
import Loading from '../components/Loading';
import { socket } from '../socket';
import {
  setJoinedGroup,
  setMarketInfo,
  setMarketRatio,
  setMyTrade,
  setSelectedGroup,
  updateCoinList,
  updateGroupList,
} from '../slices/tradeSlice';
import { setPlayerBalance } from '../slices/authSlice';
import { enqueueSnackbar } from 'notistack';

const TradePage = () => {
  const { isLoading, selectedCoin, selectedGroup, joinedGroup } = useSelector(
    (state) => state.trade
  );
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchBinaryOptionList());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(fetchBinaryOptionDetail({ id }));

      return () => {
        console.log('======masuk cleanup=======');
        socket.removeAllListeners();
        socket.disconnect();
        // dispatch(resetTradingState());
      };
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (selectedCoin && selectedGroup && !socket.connected) {
      socket.connect();
    }

    const onConnect = () => {
      console.log('socket connected!');
      const tradeRoomPayload = {
        token: localStorage.getItem('USER_KEY'),
        binary_option_id: selectedCoin._id,
        trade_group_id: selectedGroup._id,
      };
      socket.emit('joinTradeRoom', tradeRoomPayload, (res) => {
        console.log(res, '====jointraderoom');
        dispatch(setMarketRatio({ ...res.trade_group_data, misc: res.misc }));
        dispatch(setJoinedGroup(true));
      });
    };

    const onDisconnect = () => {
      console.log('socket disconeected!');
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, [dispatch, selectedCoin, selectedGroup]);

  useEffect(() => {
    if (!joinedGroup && selectedCoin && selectedGroup && socket.connected) {
      socket.removeAllListeners();
      const tradeRoomPayload = {
        token: localStorage.getItem('USER_KEY'),
        binary_option_id: selectedCoin._id,
        trade_group_id: selectedGroup._id,
      };
      socket.emit('joinTradeRoom', tradeRoomPayload, (res) => {
        console.log(res, '====jointraderoom');
        dispatch(setMarketRatio({ ...res.trade_group_data, misc: res.misc }));
        dispatch(setJoinedGroup(true));
      });
    }
  }, [dispatch, joinedGroup, selectedCoin, selectedGroup]);

  useEffect(() => {
    const onMarketInfo = (marketInfo) => {
      console.log(marketInfo, '====onMarketInfo');
      dispatch(setMarketInfo(marketInfo));
      if (marketInfo && new Date(marketInfo.endDate) > new Date()) {
        enqueueSnackbar('Trade Open', { variant: 'info' });
      }
    };

    if (socket.connected && joinedGroup) {
      console.log('socket listens MARKET_INFO!');
      socket.on('market_info', onMarketInfo);
    }
  }, [dispatch, joinedGroup]);

  useEffect(() => {
    const onTradeGroupData = (tradeGroupData) => {
      console.log(tradeGroupData, '====tradegorupdara');
      dispatch(setSelectedGroup(tradeGroupData));
      dispatch(setMarketRatio(tradeGroupData));
    };

    if (socket.connected && joinedGroup) {
      console.log('socket listens TRADE_GROUP_DATA!');
      socket.on('trade_group_data', onTradeGroupData);
    }
  }, [dispatch, joinedGroup]);

  useEffect(() => {
    const onCKUBalance = (ckuBalance) => {
      console.log(ckuBalance, '====ckubalance');
      if (ckuBalance !== null && ckuBalance !== undefined) {
        dispatch(setPlayerBalance(ckuBalance));
      }
    };

    if (socket.connected && joinedGroup) {
      console.log('socket listens CKU_BALANCE!');
      socket.on('cku_balance', onCKUBalance);
    }
  }, [dispatch, joinedGroup]);

  useEffect(() => {
    const onTradeResult = (tradeResult) => {
      console.log(tradeResult, '=====tradeResult');
      if (tradeResult && tradeResult.trade_result !== undefined) {
        enqueueSnackbar(
          `Results ${
            Math.sign(tradeResult.trade_result) === -1
              ? tradeResult.trade_result
              : '+' + tradeResult.trade_result
          } from ${tradeResult.coin_info.currency}/USD`,
          {
            variant:
              Math.sign(tradeResult.trade_result) === -1 ? 'error' : 'success',
            autoHideDuration: 5000,
          }
        );
        dispatch(setMyTrade({ bear: 0, bull: 0 }));
      }
    };

    if (socket.connected && joinedGroup) {
      console.log('socket listens TRADE_RESULT!');
      socket.on('trade_result', onTradeResult);
    }
  }, [dispatch, joinedGroup]);

  useEffect(() => {
    const onMyTrade = (myTrade) => {
      console.log(myTrade, '====mytrade');
      if (myTrade && myTrade.trade_group_id) dispatch(setMyTrade(myTrade));
    };

    if (socket.connected && joinedGroup) {
      console.log('socket listens MY_TRADE!');
      socket.on('my_trade', onMyTrade);
    }
  }, [dispatch, joinedGroup]);

  useEffect(() => {
    const onTradeGroupList = (groupList) => {
      if (groupList && groupList.length) {
        dispatch(updateGroupList(groupList));
      }
    };

    if (socket.connected && joinedGroup) {
      console.log('socket listens TRADE_GROUP_LIST!');
      socket.on('trade_group_list', onTradeGroupList);
    }
  }, [dispatch, joinedGroup]);

  useEffect(() => {
    const onBinaryOptionList = (optionList) => {
      console.log(optionList, '====optionlisttt');
      if (optionList && optionList.length) {
        dispatch(updateCoinList(optionList));
        const index = optionList.findIndex(
          (option) => option._id === selectedCoin._id
        );
        if (index === -1) {
          socket.removeAllListeners();
          dispatch(setMarketInfo(null));
          enqueueSnackbar(
            `Page is no longer available, Please select another coin to trade`,
            { variant: 'error', autoHideDuration: 5000 }
          );
        } else {
          dispatch(fetchBinaryOptionDetail({ id }));
        }
      }
    };

    if (socket.connected && joinedGroup) {
      socket.on('binary_option_list', onBinaryOptionList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, joinedGroup]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      <LayoutTrade>
        <div className="flex flex-col sm:flex-row sm:justify-between w-full sm:w-[100%] sm:h-[calc(100vh-80px)]">
          <div className="flex flex-row justify-between px-[8px] gap-[4px] sm:hidden">
            <CurrencySelect width="w-[48%]" />
            {/* <ChartFilter /> */}
            <TradeGroup width="w-[48%]" />
          </div>
          <div className="sm:w-[85%] w-full h-[calc(100vh-300px)] sm:h-[80%] relative">
            {/* {selectedChart === 'Candlestick' ? (
            <ApexCandleStick />
          ) : (
            <ApexLineChart />
          )} */}
            <TradingViewWidget />
            {/* <span className="hidden sm:flex">
            <ChartFilter />
          </span>
          <span className="sm:w-[90%] w-[85%] left-0 m-auto flex flex-row justify-between items-center absolute z-10 top-1/2 transform -translate-y-1/2">
            <button className="opacity-10 hover:opacity-100 flex" type="button">
              <ArrowCircleLeftIcon style={{ fontSize: '24px' }} />
            </button>
            <button className="opacity-10 hover:opacity-100 flex" type="button">
              <ArrowCircleRightIcon style={{ fontSize: '24px' }} />
            </button>
          </span> */}
          </div>
          <div className="sm:w-[15%] sm:px-[12px] gap-[12px] flex flex-col-reverse sm:flex-col">
            <div className="sm:block hidden">
              <TradeGroup />
            </div>
            <TradeOptions />
            <div className="relative min-h-[46px] z-10 flex flex-row justify-between items-center w-full px-[12px] sm:px-0">
              <PromotionalBanner />
              <RoundPrize />
            </div>
          </div>
        </div>
      </LayoutTrade>
    </>
  );
};

export default TradePage;
