const ActionButton = ({
  type,
  title,
  classes,
  iconName = '',
  iconPos = '',
  iconSize = 0,
  iconSrc = '',
  disabled = false,
  ...props
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={`bg-blue text-white-f py-[24px] px-[16px] rounded-[24px] flex flex-row justify-center items-center font-poppins-semibold text-lg text-center w-full ${
        disabled && 'opacity-50'
      } ${classes}`}
      {...props}
    >
      {iconPos === 'left' && iconName && iconSize && !iconSrc ? (
        <ion-icon
          name={iconName}
          style={{
            width: `${iconSize}px`,
            height: `${iconSize}px`,
            marginRight: '10px',
          }}
        ></ion-icon>
      ) : null}
      {iconSrc ? (
        <img
          src={iconSrc}
          alt="icon-button"
          className="w-[20px] h-[20px] mr-[10px]"
        />
      ) : null}
      {title}
      {iconPos === 'right' && iconName && iconSize ? (
        <ion-icon
          name={iconName}
          style={{
            width: `${iconSize}px`,
            height: `${iconSize}px`,
            marginLeft: '10px',
          }}
        ></ion-icon>
      ) : null}
    </button>
  );
};

export default ActionButton;
