import { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import GetLC from '../Wallet/GetLC';
import SendLC from '../Wallet/SendLC';
import WithdrawLC from '../Wallet/WithdrawLC';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../Loading';
import SendWithdrawOTP from '../Wallet/SendWithdrawOTP';
import { fetchWalletList } from '../../actions/wallet';
import { resetData } from '../../slices/walletSlice';

const Wallet = ({ closeModal }) => {
  const sections = ['Get CKU', 'Send CKU', 'Withdraw'];
  const { isLoading } = useSelector((state) => state.auth);
  const isLoadingWallet = useSelector((state) => state.wallet.isLoading);
  const refetchWalletList = useSelector(
    (state) => state.wallet.refetchWalletList
  );
  const [selectedSection, setSelectedSection] = useState(sections[0]);
  const [otpModal, setOtpModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (refetchWalletList) {
      dispatch(fetchWalletList());
    }
    return () => dispatch(resetData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchWalletList]);

  useEffect(() => {
    if (otpModal === 1) closeModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpModal]);

  return (
    <>
      {isLoading || isLoadingWallet ? <Loading /> : null}
      <Modal closeModal={closeModal} title="Wallet">
        {otpModal ? (
          <SendWithdrawOTP section={otpModal} closeModal={setOtpModal} />
        ) : null}
        <div className="flex flex-row w-full mb-[16px] border justify-between border-black-100 rounded-lg">
          {sections.map((section, idx) => (
            <button
              key={`${section}-${idx}`}
              type="button"
              className={`w-[33.3%] font-poppins-semibold py-[10px] text-lg border-r border-r-black-100 last:border-r-0 first:rounded-tl-lg first:rounded-bl-lg last:rounded-tr-lg last:rounded-br-lg ${
                selectedSection === section ? 'bg-blue text-white' : 'bg-white'
              }`}
              onClick={() => setSelectedSection(section)}
            >
              {section}
            </button>
          ))}
        </div>
        {selectedSection === 'Get CKU' ? (
          <GetLC />
        ) : selectedSection === 'Send CKU' ? (
          <SendLC setOtpModal={setOtpModal} />
        ) : selectedSection === 'Withdraw' ? (
          <WithdrawLC setOtpModal={setOtpModal} />
        ) : null}
      </Modal>
    </>
  );
};

export default Wallet;
