import { io } from 'socket.io-client';

const URL =
  process.env.REACT_APP_API === 'production'
    ? 'https://duotrx.com'
    : 'https://staging.duotrx.com';

export const socket = io(URL, {
  autoConnect: false,
});
