import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPagesContent } from '../actions/common';
import Loading from '../components/Loading';
import { setFAQIsOpen } from '../slices/commonSlice';
import LayoutUser from '../components/Layout/LayoutUser';

const FAQPage = () => {
  const { isLoading, contentList, contentBanner } = useSelector(
    (state) => state.common
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPagesContent({ content_type: 'FAQ' }));
  }, [dispatch]);

  return (
    <LayoutUser showTabMenu={true}>
      {isLoading ? <Loading /> : null}
      <main className="pb-[84px]">
        <div className="h-[35vh] w-full">
          {contentBanner ? (
            <img
              src={contentBanner}
              className="w-full h-full object-cover"
              alt="FAQ Banner"
            />
          ) : null}
        </div>
        <div className="flex flex-col px-[16px] sm:w-[50%] sm:m-auto">
          <h3 className="text-2xl font-poppins-semibold mt-[60px] mb-[16px]">
            Frequently Asked Questions
          </h3>
          {contentList.length ? (
            <div className="flex flex-col">
              {contentList.map((content, idx) => (
                <div key={content._id}>
                  <div
                    className="cursor-pointer relative z-10 flex flex-row justify-between items-center px-[24px] py-[18px] bg-[#DFEEFF] border border-[#DADADA] text-lg font-poppins-semibold h-fit"
                    onClick={() => dispatch(setFAQIsOpen(idx))}
                  >
                    <p>{content.title}</p>
                    <ion-icon
                      name={content.is_open ? 'remove-circle' : 'add-circle'}
                      style={{
                        width: '20px',
                        height: '20px',
                        color: '#2F80ED',
                      }}
                    ></ion-icon>
                  </div>
                  <div
                    className={`whitespace-pre-wrap px-[24px] bg-[#DFEEFF] ${
                      content.is_open
                        ? 'max-h-[50vh] overflow-auto h-full opacity-100 py-[18px] border border-[#DADADA]'
                        : 'max-h-0 opacity-0 h-0 py-0 border-0'
                    } transition-all duration-300`}
                  >
                    <p
                      className={`text-sm ${
                        content.is_open ? 'block' : 'hidden'
                      }`}
                    >
                      {content.content}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No FAQ found!</p>
          )}
        </div>
      </main>
    </LayoutUser>
  );
};

export default FAQPage;
