import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIsLoggedIn } from '../../hooks/useAuth';
import { Box, Drawer } from '@mui/material';
import Logo from '../../assets/imgs/duotrx-logo.png';
import {
  setIsMobileNavOpen,
  setSelectedMenu,
  updateMenus,
} from '../../slices/navbarSlice';
import ActionButton from '../Button/ActionButton';

const Navbar = ({ isHome = false, is404 = false }) => {
  const { selectedMenu, isMobileNavOpen, menus } = useSelector(
    (state) => state.navbar
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      const hasLogin = menus.filter((menu) => menu.path === '/login');
      if (hasLogin.length) {
        const parsedMenu = JSON.parse(JSON.stringify(menus));
        parsedMenu.splice(0, 1, { name: 'Trade', path: '/trade' });
        dispatch(updateMenus(parsedMenu));
      }
    }
  }, [dispatch, isLoggedIn, menus]);

  useEffect(() => {
    const pathname = location.pathname;
    const selected = menus.filter((menu) => menu.path === pathname);
    if (selected.length) {
      dispatch(setSelectedMenu(selected[0].name));
    } else {
      dispatch(setSelectedMenu(''));
    }
  }, [dispatch, location.pathname, menus]);

  return (
    <>
      <div
        className={`flex flex-row sm:justify-between items-center w-full py-[20px] px-[16px] sm:px-[48px] ${
          isHome ? 'bg-transparent absolute top-0' : ' bg-white-f'
        }`}
      >
        <div className="flex flex-row items-center">
          <div
            className="ml-[-10px] sm:ml-0 cursor-pointer w-[170px] h-[42px]"
            onClick={() => navigate('/')}
          >
            <img
              src={Logo}
              alt="duotrx-logo"
              className="w-full h-full object-contain"
            />
          </div>
          <div className="hidden sm:flex flex-row ml-[45px] gap-[30px]">
            {menus.map((menu) => (
              <button
                key={menu.name}
                type="button"
                onClick={() => {
                  if (menu.path) navigate(menu.path);
                }}
                className={`text-[18px] text-left ${
                  selectedMenu === menu.name && 'underline'
                }`}
              >
                {menu.name}
              </button>
            ))}
          </div>
        </div>
        <ActionButton
          title={isLoggedIn ? `Profile` : 'Join Now'}
          iconPos="left"
          iconSize="24"
          iconName="log-out-outline"
          classes="!w-fit hidden sm:flex !py-[10px] !px-[30px] !rounded-lg"
          onClick={() => navigate(isLoggedIn ? '/profile' : '/login')}
        />
        <button
          className="flex ml-auto sm:hidden"
          onClick={() => dispatch(setIsMobileNavOpen(!isMobileNavOpen))}
        >
          <ion-icon
            name="menu-outline"
            style={{
              width: '30px',
              height: '30px',
              color: '#212121',
            }}
          ></ion-icon>
        </button>
      </div>
      <Drawer
        anchor="right"
        open={isMobileNavOpen}
        id="nav-drawer"
        PaperProps={{
          sx: {
            boxShadow: 'none',
          },
        }}
        ModalProps={{
          hideBackdrop: true,
        }}
        onClose={() => dispatch(setIsMobileNavOpen(false))}
      >
        <Box
          role="presentation"
          sx={{
            width: '100vw',
            backgroundColor: '#ffffff',
            height: '100vh',
            padding: '32px 16px',
          }}
          onClick={() => dispatch(setIsMobileNavOpen(false))}
          onKeyDown={() => dispatch(setIsMobileNavOpen(false))}
        >
          <div className="flex flex-col h-full relative items-center justify-center">
            <button type="button" className="absolute top-0 right-0">
              <ion-icon
                name="close-circle-outline"
                style={{
                  width: '37px',
                  height: '37px',
                }}
              ></ion-icon>
            </button>
            {menus.map((menu) => (
              <button
                key={menu.name}
                type="button"
                onClick={() => {
                  if (menu.path) navigate(menu.path);
                }}
                className={`mb-[28px] text-center text-2xl font-poppins-semibold ${
                  selectedMenu === menu.name && 'underline'
                }`}
              >
                {menu.name}
              </button>
            ))}
            <ActionButton
              title={isLoggedIn ? `Profile` : 'Join Now'}
              iconPos="left"
              iconSize="24"
              iconName="log-out-outline"
              classes="!w-fit flex !py-[10px] !px-[45px] !rounded-lg !text-2xl"
              onClick={() => navigate(isLoggedIn ? '/profile' : '/login')}
            />
            <p className="text-sm absolute bottom-0 text-center w-full">
              Copyright © 2024 Duotrx Software ltd.
            </p>
          </div>
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar;
