import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { setModalSection } from '../../slices/tradeSlice';
import { thousandSeparator } from '../../utils/math';

const RoundPrize = () => {
  const { modalSection, marketInfo } = useSelector((state) => state.trade);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  if (width >= 1024) {
    return (
      <div className="flex flex-col w-full right-[12px] p-[12px] gap-[4px] sm:bg-white-btn bg-[rgba(255,255,255,0.10)] backdrop-blur-md rounded-lg text-sm">
        <div className="flex flex-row justify-between items-center gap-[24px] sm:gap-0 text-[#788793]">
          <span className="flex flex-row items-center gap-[4px]">
            <ion-icon
              name="pricetag-outline"
              style={{ width: '16px', height: '16px' }}
            ></ion-icon>
            Round Price
          </span>
        </div>
        <span className="flex flex-col">
          <p className="font-poppins-semibold">This Round</p>
          <p>
            Start ={' '}
            {marketInfo ? thousandSeparator(marketInfo.price_to_beat) : 0}
          </p>
          <p>End = TBD</p>
        </span>
        <span className="flex flex-col">
          <p className="font-poppins-semibold">Previous Round</p>
          <p>
            Start ={' '}
            {marketInfo ? thousandSeparator(marketInfo.prev_start_price) : 0}
          </p>
          <p>
            End ={' '}
            {marketInfo ? thousandSeparator(marketInfo.prev_end_price) : 0}
          </p>
        </span>
      </div>
    );
  } else {
    return (
      <div
        className={`flex absolute right-[12px] ${
          modalSection === 'ROUND_PRICE'
            ? 'top-[-140px] w-[50%]'
            : 'top-0 w-fit'
        } transition-all duration-300 flex-col border border-[#DADADA] p-[12px] gap-[4px] sm:bg-white-btn bg-[rgba(255,255,255,0.10)] backdrop-blur-md rounded-lg text-sm`}
      >
        <div className="flex flex-row justify-between items-center gap-[24px] sm:gap-0 text-[#788793]">
          <span
            className="flex flex-row items-center gap-[4px]"
            onClick={() => dispatch(setModalSection('ROUND_PRICE'))}
          >
            <ion-icon
              name="pricetag-outline"
              style={{ width: '16px', height: '16px' }}
            ></ion-icon>
            Round Price
          </span>
        </div>
        <div
          className={`transition-all duration-300 ${
            modalSection === 'ROUND_PRICE'
              ? 'max-h-[50vh] overflow-auto h-full opacity-100'
              : 'max-h-0 opacity-0 h-0'
          }`}
        >
          <span className="flex flex-col mb-[8px]">
            <p className="font-poppins-semibold">This Round</p>
            <p>
              Start ={' '}
              {marketInfo ? thousandSeparator(marketInfo.price_to_beat) : 0}
            </p>
            <p>End = TBD</p>
          </span>
          <span className="flex flex-col">
            <p className="font-poppins-semibold">Previous Round</p>
            <p>
              Start ={' '}
              {marketInfo ? thousandSeparator(marketInfo.prev_start_price) : 0}
            </p>
            <p>
              End ={' '}
              {marketInfo ? thousandSeparator(marketInfo.prev_end_price) : 0}
            </p>
          </span>
          <button
            className="text-[10px] text-[#78879380] w-full"
            onClick={() => dispatch(setModalSection(''))}
          >
            Tap to close
          </button>
        </div>
      </div>
    );
  }
};

export default RoundPrize;
