import { TableBody, TableHead, TableRow } from '@mui/material';
import StyledTable from '../Table/StyledTable';
import StyledTableContainer from '../Table/StyledTableContainer';
import StyledTableCell from '../Table/StyledTableCell';
import { useDispatch, useSelector } from 'react-redux';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { fetchPlayerFriendsList } from '../../actions/player';
import { useEffect } from 'react';
import { setFriendsPageData } from '../../slices/playerSlice';
import DataLimit from '../Table/DataLimit';
import Pagination from '../Table/Pagination';
import { thousandSeparator } from '../../utils/math';
import moment from 'moment-timezone';
import { currentTimezone } from '../../utils/date';

const Referral = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { friendsPageData, friendsList, friendsTotalPages } = useSelector(
    (state) => state.player
  );
  const { copyText } = useCopyToClipboard();

  const handlePageChange = (ev) => {
    if (ev.selected !== friendsPageData.page) {
      dispatch(setFriendsPageData({ page: ev.selected }));
    }
  };

  useEffect(() => {
    dispatch(fetchPlayerFriendsList(friendsPageData));
  }, [dispatch, friendsPageData]);

  if (userInfo) {
    return (
      <div className="flex flex-col sm:flex-row sm:gap-[16px]">
        <div className="flex flex-col sm:w-[35%]">
          <div className="flex flex-col py-[4px] mb-[8px]">
            <h3 className="text-lg font-poppins-bold text-blue mb-[4px]">
              Easy Refer
            </h3>
            <p className="text-sm">
              Earn from EVERY trade your friends make if they register with your
              referral link
            </p>
          </div>
          <div className="flex flex-col w-full items-center gap-[8px]">
            <h5 className="text-sm font-poppins-bold">Share and earn today!</h5>
            <div className="border border-black-100 bg-light-yellow px-[12px] py-[4px] flex flex-col w-full">
              <h5 className="text-sm font-poppins-bold">Referral Link</h5>
              <div className="flex flex-row justify-between items-center mt-[8px]">
                <p className="text-sm font-poppins-bold text-light-green w-[80%]">
                  {userInfo.referral_tag}
                </p>
                <button
                  type="button"
                  className="flex"
                  onClick={() =>
                    copyText(
                      userInfo.referral_tag,
                      'Successfully copied Referral Link'
                    )
                  }
                >
                  <ion-icon
                    name="copy-outline"
                    style={{ width: '24px', height: '24px' }}
                  ></ion-icon>
                </button>
              </div>
            </div>
            <h5 className="text-sm font-poppins-bold">or</h5>
            <div className="border border-black-100 bg-light-yellow px-[12px] py-[4px] flex flex-col w-full">
              <h5 className="text-sm font-poppins-bold">Referral Code</h5>
              <div className="flex flex-row justify-between items-center mt-[8px]">
                <p className="text-sm font-poppins-bold text-light-green">
                  {userInfo.player_id}
                </p>
                <button
                  type="button"
                  className="flex"
                  onClick={() =>
                    copyText(
                      userInfo.player_id,
                      'Successfully copied Referral Code'
                    )
                  }
                >
                  <ion-icon
                    name="copy-outline"
                    style={{ width: '24px', height: '24px' }}
                  ></ion-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:w-[55%] sm:pl-[16px] sm:border-l sm:border-l-black-60 sm:border-t-0 sm:mt-0 sm:py-0 border-t border-t-black-60 mt-[14px] py-[24px]">
          <h3 className="text-base font-poppins-semibold mb-[16px] text-blue">
            Referral List
          </h3>
          <DataLimit
            initialLimit={friendsPageData.dataLimit}
            setter={setFriendsPageData}
            classList="mb-[16px]"
          />
          {friendsList.length ? (
            <div>
              <StyledTableContainer style={{ background: 'transparent' }}>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell style={{ background: 'transparent' }}>
                        Join Date
                      </StyledTableCell>
                      <StyledTableCell style={{ background: 'transparent' }}>
                        Email
                      </StyledTableCell>
                      <StyledTableCell style={{ background: 'transparent' }}>
                        Trade Amount
                      </StyledTableCell>
                      <StyledTableCell style={{ background: 'transparent' }}>
                        Comission
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {friendsList.map((friend) => (
                      <TableRow key={friend.email}>
                        <StyledTableCell style={{ background: 'transparent' }}>
                          {moment(friend.join_date)
                            .tz(currentTimezone())
                            .format('DD-MM-YYYY, HH:mm')}
                        </StyledTableCell>
                        <StyledTableCell style={{ background: 'transparent' }}>
                          {friend.email}
                        </StyledTableCell>
                        <StyledTableCell style={{ background: 'transparent' }}>
                          {thousandSeparator(friend.total_trades)}
                        </StyledTableCell>
                        <StyledTableCell style={{ background: 'transparent' }}>
                          CKU {thousandSeparator(friend.commission)}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </StyledTableContainer>
              <Pagination
                totalPages={friendsTotalPages}
                pageData={friendsPageData}
                onChange={handlePageChange}
              />
            </div>
          ) : (
            <p>Data not found!</p>
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default Referral;
