const Input = ({
  title,
  placeholder,
  type = 'text',
  register,
  name,
  classes,
  setValueAs,
  errors,
  isDisabled = false,
}) => {
  return (
    <div className={`flex flex-col mb-[18px] ${classes}`}>
      <label className="text-sm">{title}</label>
      {name === 'phone_number' ? (
        <input
          type={type}
          placeholder={placeholder}
          {...register(name, { setValueAs: (value) => `${value}` })}
          className="text-sm p-[8px] border border-black-100 rounded-[6px] outline-0 bg-white"
        />
      ) : name === 'cku_amount' ? (
        <input
          type="text"
          placeholder={placeholder}
          {...register(name, {
            setValueAs: (value) =>
              value && typeof value === 'string'
                ? +value.replace(/[^0-9.]/g, '')
                : value,
          })}
          className="text-sm py-[11px] px-[8px] border border-black-100 rounded-[6px] outline-0 bg-white"
        />
      ) : (
        <input
          type={type}
          disabled={isDisabled}
          placeholder={placeholder}
          {...register(name)}
          className={`text-sm p-[8px] border border-black-100 rounded-[6px] outline-0 bg-white ${
            isDisabled ? 'opacity-30 cursor-not-allowed' : 'opacity-100'
          }`}
        />
      )}

      {errors && errors[name] && (
        <p className="text-xs text-red">{errors[name].message}</p>
      )}
    </div>
  );
};

export default Input;
